import CandidateModal from 'app/modals/CandidateModal';

export const parseCandidatesTableData = (data) => {
  const arr = [];
  data.forEach((el) => {
    // eslint-disable-next-line no-unused-vars
    const {candidates, ...rest} = el;

    if (el?.candidates?.length) {
      el.candidates.forEach((cand, index) => {
        const {billingRate} = new CandidateModal(cand);
        const {
          available_on,
          hid: candidate_hid,
          id: candidate_id,
          resource,
          billing_rate,
          status: candidate_status,
          billing_rate_period,
        } = cand;

        const clearCandidate = {
          available_on: available_on || resource?.available_on,
          candidate_hid,
          candidate_id,
          resource,
          billing_rate: billing_rate || resource?.billing_rate,
          billing_rate_min: billing_rate ? null : resource?.billing_rate_min,
          billing_rate_max: billing_rate ? null : resource?.billing_rate_max,
          candidate_status,
          billing_rate_period,
          billingRate,
        };
        index === 0
          ? arr.push({...rest, ...clearCandidate})
          : arr.push(clearCandidate);
      });
    } else {
      arr.push(rest);
    }
  });
  return arr;
};

export const getCandidates = (candidates, integration) => {
  if (!Array.isArray(candidates) || !candidates) {
    return [];
  }
  return candidates.map((cand) => {
    return new CandidateModal({...cand, integration});
  });
};
