import {DATE_FORMATS} from 'app/config/app';
import {APPLICANT_TYPE_KEYS, PERIODS} from 'app/constants';

import {
  CANDIDATE_REJECT_REASON_LABELS,
  CANDIDATE_STATUSES,
  INTERVIEW_STAGE_LABELS,
} from '../constants/candidate-statuses';
import {capitalizeFirstLetter} from '../utils/helpers/Capitalize';
import {
  formatBillingRateRage,
  formatDate,
  formatSecondsToDate,
} from '../utils/helpers/DataFormatHelper';
import RequisitionModal from './RequisitionModal';
import ResourceModal from './ResourceModal';

const getBillingRate = ({billingRate, billing_rate_period}) => {
  if (billingRate === 'N/A') {
    return billingRate;
  }
  return `${billingRate} ${
    billingRate && PERIODS[billing_rate_period]
      ? ` / ${PERIODS[billing_rate_period]}`
      : ''
  }`;
};

class CandidateModal extends ResourceModal {
  constructor({requisition, ...candidate} = {}, resource = null) {
    const arg = resource || candidate.resource || candidate.applicant;
    super(arg);
    this.applicant = candidate.applicant;
    this.id = candidate?.id || '';
    this.hid = candidate?.hid || '';
    this.avatar = candidate?.user?.avatar_url || this.resource?.avatar || '';
    this.candidate_updated_at = formatSecondsToDate(candidate?.updated_at);
    this.completed_engagements =
      resource?.completed_engagements || candidate?.completed_engagements || 0;
    this.inquired_on = formatSecondsToDate(candidate?.created_at, 'MM/DD/YYYY');
    this.supplier = this.buildExpandSupplier(candidate, true);
    this.supplier_active_clients = candidate?.supplier_active_clients || 0;
    this.supplier_available_resources =
      candidate?.supplier_available_resources || 0;
    this.supplier_completed_engagements =
      candidate?.supplier_completed_engagements || 0;
    this.supplier_engagements = candidate?.supplier_engagements || 0;
    this.supplier_inquiries = candidate?.supplier_inquiries || 0;
    this.supplier_relationship_status =
      candidate?.supplier_relationship_status || 'N/A';
    this.supplier_submissions = candidate?.supplier_submissions || 0;
    this.requisition =
      typeof requisition === 'object'
        ? new RequisitionModal(requisition)
        : requisition;
    this.status =
      CANDIDATE_STATUSES[candidate?.status] ||
      capitalizeFirstLetter(candidate?.status);
    this.candidate_status = this.status;
    this.status_key = candidate?.status;
    this.integration = candidate?.integration;
    this.interview_stage = candidate?.interview_stage;
    this.integration_interview_stage = candidate?.integration_interview_stage;
    this.interviewStageLabel = this.getStageLabel();
    this.reject_reason = candidate?.reject_reason;
    this.reject_reason_notes = candidate?.reject_reason_notes;
    this.integration_reject_reason = candidate?.integration_reject_reason;
    this.rejectReasonLabel = this.getRejectLabel();
    this.isRejectedCandidate = candidate?.status === 'rejected';
    this.availabilityName = this.status;
    this.billing_rate = candidate?.billing_rate || this.resource?.billing_rate;
    this.billingRate = candidate?.billing_rate
      ? this.formatBillingRateRage(candidate?.billing_rate, null, null, false)
      : this.billingRate;
    this.billingRateLabel = getBillingRate(this);
    this.submission = candidate?.submission || null;
    this.inquiry = candidate?.inquiry || null;
    this.created_from_job_board = !!candidate?.created_from_job_board;
    this.isConfirmed =
      !!candidate?.available_on || candidate?.status !== CANDIDATE_STATUSES.NEW;
    this.isAvailable = !!candidate?.available_on;
    this.available_on =
      formatDate(candidate?.available_on, DATE_FORMATS.md) ||
      formatDate(this.resource?.available_on, DATE_FORMATS.md);
    this.skills =
      candidate?.skill_tags?.data?.map(
        ({expertise_level, years_experience, id, tag, last_used}) => ({
          id,
          last_used,
          expertise_level,
          years_experience,
          skill: tag?.name || '',
        }),
      ) ||
      resource?.skill_tags?.data?.map(
        ({expertise_level, years_experience, id, tag, last_used}) => ({
          id,
          last_used,
          expertise_level,
          years_experience,
          skill: tag?.name || '',
        }),
      ) ||
      [];
    this.skill_tags =
      candidate?.skill_tags?.data?.map(
        ({expertise_level, years_experience, id, tag, last_used}) => ({
          id,
          last_used,
          expertise_level,
          years_experience,
          skill: tag?.name || '',
        }),
      ) ||
      resource?.skill_tags?.data?.map(
        ({expertise_level, years_experience, id, tag, last_used}) => ({
          id,
          last_used,
          expertise_level,
          years_experience,
          skill: tag?.name || '',
        }),
      ) ||
      [];
    this.user = candidate?.user || null;
    this.base_salary =
      candidate?.base_salary || this.resource?.base_salary || null;
    this.base_salary_min =
      candidate?.base_salary_min || this.resource?.base_salary_min || null;
    this.base_salary_max =
      candidate?.base_salary_max || this.resource?.base_salary_max || null;
    this.base_salary_period = this.resource?.base_salary_period || null;
    this.baseSalaryLabel = formatBillingRateRage(
      this.base_salary,
      this?.base_salary_min,
      this?.base_salary_max,
      false,
    );
    this.isPermanent =
      candidate?.applicant?.type === APPLICANT_TYPE_KEYS.permanent;
    this.score = candidate?.ai_match;
  }
  getStageLabel() {
    const isActive = this.integration?.isActive;
    console.log('isActive-->', isActive);
    if (this.integration_interview_stage && isActive) {
      const [key, value] = Object.entries(this.integration_interview_stage)[0];
      return value ? value : key;
    }
    return INTERVIEW_STAGE_LABELS[this.interview_stage] || null;
  }

  getRejectLabel() {
    if (this.integration_reject_reason) {
      return this.integration_reject_reason.name;
    }
    return CANDIDATE_REJECT_REASON_LABELS[this?.reject_reason] || null;
  }
}

export default CandidateModal;
