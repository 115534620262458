import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Table} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType.js';
import {useGetSubmissions} from 'app/services/submissionService.js';
import {
  getColumns,
  getExpandedCols,
} from 'app/shared/components/Tables/Submissions/columns';
import {getCandidates} from 'app/utils/helpers/ParseCandidatesTableData.js';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

const Submissions = ({
  defaultQueryParams,
  handleSetTabView,
  isShow,
  isOne,
  isHideSupplier,
  isDirectHire,
  isHideBilling,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState('alpha');

  const {isLoading, data} = useGetSubmissions({
    page: currentPage - 1,
    limit: pageSize,
    ...defaultQueryParams,
  });

  const {submissions, total_count} = data || {submissions: [], total_count: 0};

  useEffect(() => {
    if (!isLoading && handleSetTabView) {
      handleSetTabView('isSubmissions', !!submissions.length);
    }
  }, [submissions, isLoading, handleSetTabView]);

  const tableData = submissions?.map((el) => ({
    ...el,
    candidates: getCandidates(el?.candidates, el?.integration),
  }));
  const expandedRowRender = (record) => {
    const subItemsColumns = getExpandedCols({
      isDirectHire,
      isHideBilling,
      isShowSupplier: !isHideSupplier && isClient,
    });

    const subItemsData = record.candidates.slice(1).map((subItem, index) => ({
      key: index,
      ...subItem,
    }));

    return (
      <Table
        scroll={{x: 'max-content'}}
        showHeader={false}
        columns={subItemsColumns}
        dataSource={subItemsData}
        pagination={false}
      />
    );
  };
  const {isClient} = useAccountType();
  const title = !isOne ? 'Submissions' : 'Submission';

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const columns = useMemo(
    () => getColumns(isClient, isHideSupplier, isDirectHire, isHideBilling),
    [isClient, isHideSupplier, isDirectHire, isHideBilling],
  );

  if (!isShow) {
    return <></>;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          {title} {!isOne && <AppTag className={'ml-1'}>{total_count}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        !isOne && (
          <AppHeaderPagination
            total={total_count}
            current={currentPage}
            pageSize={pageSize}
            onChange={onPageChange}
            hideStartBorder
            sortByOptions={[
              {label: 'Alphabetically', value: 'alpha'},
              {label: 'Created Date', value: 'date'},
              {label: 'Created By', value: 'owner'},
            ]}
            sortBy={sortBy}
            onSortByChange={setSortBy}
          />
        )
      }>
      <AppTable
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
          rowExpandable: (record) => record?.candidates?.length > 1,
          columnWidth: null,
          expandIcon: null,
        }}
        scroll={{x: 'max-content'}}
        rowClassName={(record) => {
          if (record.rowClassNames) {
            if (Array.isArray(record.rowClassNames)) {
              return record.rowClassNames.join(' ');
            }
            return record.rowClassNames;
          }
        }}
        pagination={
          !isOne &&
          getTablePagination(
            pageSize,
            currentPage,
            total_count,
            onShowSizeChange,
            onPageChange,
          )
        }
      />
    </AppCard>
  );
};

Submissions.propTypes = {
  defaultQueryParams: PropTypes.object,
  handleSetTabView: PropTypes.func,
  isShow: PropTypes.bool,
  isOne: PropTypes.bool,
  isHideSupplier: PropTypes.bool,
  isDirectHire: PropTypes.bool,
  isHideBilling: PropTypes.bool,
};

Submissions.defaultProps = {
  defaultQueryParams: {},
  isShow: false,
  isOne: false,
  isHideSupplier: false,
  isDirectHire: false,
  isHideBilling: false,
};
export default Submissions;
