import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppRevealCard from '@wieldy/components/AppRevealCard';
import {Button, Typography} from 'antd';
import ClientModal from 'app/modals/ClientModal';
import {useAddBookmarkToRequisition} from 'app/services/requisitionService';
import {ClientCard} from 'app/shared/client';
import {BottomCounterBlock} from 'app/shared/components/blocks';
import RevealCardFooter from 'app/shared/components/RevealCardFooter';
import {SupplierCard} from 'app/shared/supplier';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {
  getCardBottomContent,
  getDirectCardBottomContent,
} from 'app/utils/helpers/GetCardBottomContent';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {RiBriefcaseFill, RiStarLine, RiStarSFill} from 'react-icons/ri';

import {WORK_TYPE_KEYS} from '../../../constants/work-types';
import RequisitionCardContent from './RequisitionCardContent';
import RequisitionCardHeader from './RequisitionCardHeader';

const Requisition = ({children, requisition, extra = null}) => {
  return (
    <AppCard
      style={{
        height: '100%',
      }}
      className='card-header-small'
      title={
        <Typography.Text className='font-medium text-uppercase text-xs'>
          <RiBriefcaseFill fontSize={18} className='text-secondary mr-2' />
          Job #{requisition?.hid}
        </Typography.Text>
      }
      extra={extra}
      headStyle={{
        backgroundColor: requisition?.statusColor,
        borderBottom: 'none',
      }}>
      <RequisitionCardHeader requisition={requisition} />
      <RequisitionCardContent requisition={requisition} />
      {children}
    </AppCard>
  );
};

Requisition.propTypes = {
  children: PropTypes.node,
  extra: PropTypes.node,
  header: PropTypes.node,
  requisition: PropTypes.object,
};

const RequisitionCard = ({
  isLoading = false,
  requisition,
  isBookmarked = false,
  showClient = false,
  showSupplier = false,
  isBoard = false,
}) => {
  const [revealed, setReveal] = useState(false);
  const mutation = useAddBookmarkToRequisition(requisition?.id);

  const handleOnBookmark = React.useCallback(() => {
    mutation.mutate(requisition?.bookmarked);
  }, [mutation, requisition?.bookmarked]);

  const bottomCounts = React.useMemo(() => {
    if (
      [
        WORK_TYPE_KEYS.full_time,
        WORK_TYPE_KEYS.part_time,
        WORK_TYPE_KEYS.temporary,
      ].includes(requisition.type) ||
      (requisition.isContract && !requisition.isC2C)
    ) {
      return getDirectCardBottomContent(requisition, {
        name: `Job ${requisition?.hid?.replace('REQ-', '')}`,
        requisition: requisition?.id,
      });
    }

    return getCardBottomContent(requisition, {
      name: `Job ${requisition?.hid?.replace('REQ-', '')}`,
      requisition: requisition?.id,
    });
  }, [requisition]);

  if (isLoading) {
    return <AppLoader />;
  }
  if (showSupplier) {
    const supplier = requisition?.supplier;
    const name = getUserName(supplier?.name, 'SUP-', 'Supplier');
    return (
      <AppRevealCard
        title='Supplier Details'
        revealed={revealed}
        setReveal={setReveal}
        onClose={() => setReveal(false)}
        revealComponent={<SupplierCard supplier={supplier} />}>
        <Requisition
          requisition={requisition}
          extra={
            <Typography.Text className='text-xs font-semibold ml-auto'>
              {requisition?.statusName}
            </Typography.Text>
          }>
          <RevealCardFooter
            title={name}
            titleLink={`/suppliers/${supplier?.id}/view-supplier`}
            subTitle={'Supplier'}
            reviews={supplier?.ratingCount}
            rating={supplier?.ratingTotal}
            style={{marginTop: '10px', marginBottom: '5px', height: 96}}
            onClick={() => setReveal(true)}
            hideExpand={isBoard}
          />
        </Requisition>
      </AppRevealCard>
    );
  }
  if (showClient) {
    const client = new ClientModal(requisition?.client);
    const name = getUserName(client?.name, 'CLI-', 'Client');
    return (
      <AppRevealCard
        title='Client Details'
        revealed={revealed}
        setReveal={setReveal}
        onClose={() => setReveal(false)}
        revealComponent={<ClientCard client={client} />}>
        <Requisition
          requisition={requisition}
          extra={
            <>
              <Typography.Text
                className='text-xs font-semibold'
                style={
                  isBookmarked ? {marginRight: '48px'} : {marginLeft: 'auto'}
                }>
                {requisition?.statusName}
              </Typography.Text>
              {isBookmarked && (
                <Button
                  type='text'
                  disabled={mutation.isLoading}
                  className={clsx(
                    {bookmarked: requisition?.bookmarked},
                    'btn-ribbon',
                  )}
                  onClick={handleOnBookmark}
                  icon={
                    requisition?.bookmarked ? (
                      <RiStarSFill className='text-xl' />
                    ) : (
                      <RiStarLine className='text-xl' />
                    )
                  }
                />
              )}
            </>
          }>
          <RevealCardFooter
            title={name}
            titleLink={`/clients/${client?.id}/view-client`}
            subTitle={'Client'}
            reviews={client?.ratingCount}
            rating={client?.ratingTotal}
            style={{marginTop: '10px', marginBottom: '5px'}}
            onClick={() => setReveal(true)}
          />
        </Requisition>
      </AppRevealCard>
    );
  }

  return (
    <Requisition
      requisition={requisition}
      extra={
        <Typography.Text className='text-xs font-semibold'>
          {requisition?.statusName}
        </Typography.Text>
      }>
      <BottomCounterBlock items={bottomCounts} />
    </Requisition>
  );
};

RequisitionCard.propTypes = {
  isLoading: PropTypes.bool,
  requisition: PropTypes.object.isRequired,
  supplierData: PropTypes.object,
  isBookmarked: PropTypes.bool,
  showClient: PropTypes.bool,
  showSupplier: PropTypes.bool,
  isBoard: PropTypes.bool,
};

export default RequisitionCard;
