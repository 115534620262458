import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import JobDetailsForm from 'app/pages/main/engagements/CreateEngagement/MainContent/JobDetails/JobDetailsForm';
import JobDetailsView from 'app/pages/main/engagements/CreateEngagement/MainContent/JobDetails/JobDetailsView';
import React from 'react';

const JobDetails = () => {
  const {currentStep} = useStep();
  if (currentStep > 4) {
    return <JobDetailsView />;
  }
  if (currentStep === 4) {
    return <JobDetailsForm />;
  }
  return (
    <Typography.Text>
      Provide additional information about this job
    </Typography.Text>
  );
};

export default JobDetails;
