import Label from '@register-app/components/additional/label';
import {Progress, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppScoreCard = ({score}) => {
  const {experience, skills, job_title, education, overall_score} = score;
  return (
    <div className='d-flex flex-gap-4 align-items-center'>
      <Progress
        width={120}
        strokeColor='#2F0D86FF'
        success={{
          strokeColor: '#2F0D86FF',
        }}
        type='circle'
        percent={overall_score}
        format={(percent) => (
          <div className='d-flex flex-column justify-content-center'>
            <Typography.Text>{`${percent}%`}</Typography.Text>
            <Typography.Text style={{fontSize: 13}}>Job Match</Typography.Text>
          </div>
        )}
      />
      <div style={{maxWidth: 200}}>
        <Label text='Experience' />
        <Progress strokeColor='red' percent={experience} />
        <Label text='Skills' />
        <Progress strokeColor='green' percent={skills} />
        <Label text='Job Title' />
        <Progress strokeColor='#F09642' percent={job_title} />
        <Label text='Education' />
        <Progress strokeColor='purple' percent={education} />
      </div>
    </div>
  );
};

AppScoreCard.propTypes = {
  score: PropTypes.object,
};

export default AppScoreCard;
