import {INDUSTRY_FOCUS} from '@register-app/constants/industries-data';
import {COUNTRIES} from 'app/constants';
import moment from 'moment';

class CompanyHelper {
  static getIndustries() {
    return Object.keys(INDUSTRY_FOCUS).map((key) => {
      return {
        key: key,
        value: key,
        label: INDUSTRY_FOCUS[key].value,
      };
    });
  }

  static getEmployeeSize() {
    return [
      {
        key: '10',
        value: '1_to_10',
        label: '1-10 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 1,
        //   [`${fieldName}[lte]`]: 10,
        // },
      },
      {
        key: '50',
        value: '11_to_50',
        label: '11-50 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 11,
        //   [`${fieldName}[lte]`]: 50,
        // },
      },
      {
        key: '200',
        value: '51_to_200',
        label: '51-200 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 51,
        //   [`${fieldName}[lte]`]: 200,
        // },
      },
      {
        key: '500',
        value: '201_to_500',
        label: '201-500 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 201,
        //   [`${fieldName}[lte]`]: 500,
        // },
      },
      {
        key: '1000',
        value: '501_to_1000',
        label: '501-1,000 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 501,
        //   [`${fieldName}[lte]`]: 1000,
        // },
      },
      {
        key: '5000',
        value: '1001_to_5000',
        label: '1,001-5,000 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 1001,
        //   [`${fieldName}[lte]`]: 5000,
        // },
      },
      {
        key: '10000',
        value: '5001_to_10000',
        label: '5,001-10,000 employees',
        // query: {
        //   [`${fieldName}[gte]`]: 5001,
        //   [`${fieldName}[lte]`]: 10000,
        // },
      },
      {
        key: '100001',
        value: 'from_10000',
        label: '10,000+ employees',
        // query: {
        //   [`${fieldName}[gt]`]: 10000,
        // },
      },
    ];
  }

  static getDeployedResouces(fieldName = 'engaged_resources') {
    return [
      {
        key: '1-10',
        value: '1-10',
        label: '1-10 resources',
        query: {
          [`${fieldName}[gte]`]: 1,
          [`${fieldName}[lte]`]: 5,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 resources',
        query: {
          [`${fieldName}[gte]`]: 11,
          [`${fieldName}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 resources',
        query: {
          [`${fieldName}[gte]`]: 21,
          [`${fieldName}[lte]`]: 50,
        },
      },
      {
        key: '50+',
        value: '50+',
        label: '50+ resources',
        query: {
          [`${fieldName}[gte]`]: 51,
        },
      },
    ];
  }

  static getOpenRequisition(fieldName = 'open_requisitions') {
    return [
      {
        key: '1-10',
        value: '1-10',
        label: '1-10 jobs',
        query: {
          [`${fieldName}[gte]`]: 1,
          [`${fieldName}[lte]`]: 5,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 jobs',
        query: {
          [`${fieldName}[gte]`]: 11,
          [`${fieldName}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 jobs',
        query: {
          [`${fieldName}[gte]`]: 21,
          [`${fieldName}[lte]`]: 50,
        },
      },
      {
        key: '50+',
        value: '50+',
        label: '50+ jobs',
        query: {
          [`${fieldName}[gte]`]: 51,
        },
      },
    ];
  }

  static completedEngagements(
    label = 'engagements',
    prefix = '',
    name = 'completed_engagements',
  ) {
    return [
      {
        key: '1-5',
        value: '1-5',
        label: `${prefix}1-5 ${label}`,
        query: {
          [`${name}[gte]`]: 1,
          [`${name}[lte]`]: 5,
        },
      },
      {
        key: '6-10',
        value: '6-10',
        label: `${prefix}6-10 ${label}`,
        query: {
          [`${name}[gte]`]: 6,
          [`${name}[lte]`]: 10,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: `${prefix}11-20 ${label}`,
        query: {
          [`${name}[gte]`]: 11,
          [`${name}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: `${prefix}21-50 ${label}`,
        query: {
          [`${name}[gte]`]: 21,
          [`${name}[lte]`]: 50,
        },
      },
      {
        key: '51-100',
        value: '51-100',
        label: `${prefix}51-100 ${label}`,
        query: {
          [`${name}[gte]`]: 51,
          [`${name}[lte]`]: 100,
        },
      },
      {
        key: '100+',
        value: '100+',
        label: `${prefix}100+ ${label}`,
        query: {
          [`${name}[gt]`]: 100,
        },
      },
    ];
  }

  static getMembershipDuration(name = 'created_at') {
    return [
      {
        key: '3M',
        value: '3M',
        label: 'At least 3 months',
        query: {
          [`${name}[lte]`]: moment().subtract(3, 'months').unix(),
        },
      },
      {
        key: '6M',
        value: '6M',
        label: 'At least 6 months',
        query: {
          [`${name}[lte]`]: moment().subtract(6, 'months').unix(),
        },
      },
      {
        key: '1Y',
        value: '1Y',
        label: 'At least 1 year',
        query: {
          [`${name}[lte]`]: moment().subtract(1, 'year').unix(),
        },
      },
      {
        key: '3Y',
        value: '3Y',
        label: 'At least 3 years',
        query: {
          [`${name}[lte]`]: moment().subtract(3, 'years').unix(),
        },
      },
    ];
  }

  static getCountries() {
    return Object.keys(COUNTRIES).map((key) => {
      return {
        key: key,
        value: key,
        label: COUNTRIES[key],
      };
    });
  }

  static getPerformanceRating(fieldName = 'rating_total') {
    return [
      {
        key: '1+',
        value: '1+',
        label: '1+ Stars',
        query: {
          [`${fieldName}[gte]`]: 1,
        },
      },
      {
        key: '2+',
        value: '2+',
        label: '2+ Stars',
        query: {
          [`${fieldName}[gte]`]: 2,
        },
      },
      {
        key: '3+',
        value: '3+',
        label: '3+ Stars',
        query: {
          [`${fieldName}[gte]`]: 3,
        },
      },
      {
        key: '4+',
        value: '4+',
        label: '4+ Stars',
        query: {
          [`${fieldName}[gt]`]: 4,
        },
      },
    ];
  }
}

export default CompanyHelper;
