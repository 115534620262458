import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import React, {useMemo} from 'react';

import MainContentClient from './MainContentClient';
import MainContentRecruiter from './MainContentRecruiter';
import MainContentResource from './MainContentResource';
import MainContentSupplier from './MainContentSupplier';

const Dashboard = () => {
  const {isSupplier} = useAccountType();
  const {isResource, isClientRecruiter} = useRole();

  const title = useMemo(() => {
    if (isResource) return 'Resource';
    if (isSupplier) return 'Supplier';
    if (isClientRecruiter) return 'Recruiter';

    return 'Client';
  }, [isResource, isSupplier, isClientRecruiter]);

  const content = useMemo(() => {
    if (isResource) return <MainContentResource />;
    if (isSupplier) return <MainContentSupplier />;
    if (isClientRecruiter) return <MainContentRecruiter />;

    return <MainContentClient />;
  }, [isResource, isSupplier, isClientRecruiter]);

  return (
    <AppPageContainer
      header={
        <AppPageHeader category='Dashboard' title={`${title} Dashboard`} />
      }>
      {content}
    </AppPageContainer>
  );
};

export default Dashboard;
