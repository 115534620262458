import {capitalize} from 'lodash';

export const CANDIDATE_STATUSES = {
  NEW: 'new',
  INTERVIEW: 'interview',
  ON_HOLD: 'on_hold',
  HIRED: 'hired',
  ENGAGED: 'engaged',
  REJECTED: 'rejected',
  CONVERTED: 'converted',
};

export const CANDIDATE_STATUSES_LABEL = Object.values(
  CANDIDATE_STATUSES,
).reduce((acc, value) => {
  acc[value] = capitalize(value.replaceAll('_', ' '));
  return acc;
}, {});
export const CANDIDATE_STATUS_OPTIONS = Object.values(CANDIDATE_STATUSES).map(
  (key) => ({value: key, label: CANDIDATE_STATUSES_LABEL[key]}),
);

export const INTERVIEW_STAGE = {
  RESUME_REVIEW: 'resume_review',
  HIRING_TEAM_REVIEW: 'hiring_team_review',
  SCREENING_CALL: 'screening_call',
  SKILLS_TEST: 'skills_test',
  HIRING_MANAGER_INTERVIEW: 'hiring_manager_interview',
  TEAM_INTERVIEW: 'team_interview',
  FINAL_INTERVIEW: 'final_interview',
  REFERENCE_CHECK: 'reference_check',
  OFFER_EXTENDED: 'offer_extended',
  BACKGROUND_CHECK: 'background_check',
  OFFER_ACCEPTED: 'offer_accepted',
  TERMS_AGREED: 'terms_agreed',
  CONTRACT_ACCEPTED: 'contract_accepted',
};

export const INTERVIEW_STAGE_LABELS = Object.values(INTERVIEW_STAGE).reduce(
  (acc, value) => {
    acc[value] = capitalize(value.replaceAll('_', ' '));
    return acc;
  },
  {},
);

export const ALL_INTERVIEW_STAGE_OPTIONS = [
  {
    label: 'Application Review',
    title: 'review',
    key: 'review',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.RESUME_REVIEW],
        value: INTERVIEW_STAGE.RESUME_REVIEW,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.HIRING_TEAM_REVIEW],
        value: INTERVIEW_STAGE.HIRING_TEAM_REVIEW,
      },
    ],
  },
  {
    label: 'Screen',
    title: 'screen',
    key: 'screen',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.SCREENING_CALL],
        value: INTERVIEW_STAGE.SCREENING_CALL,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.SKILLS_TEST],
        value: INTERVIEW_STAGE.SKILLS_TEST,
      },
    ],
  },
  {
    label: 'Interview',
    title: 'interview',
    key: 'interview',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.HIRING_MANAGER_INTERVIEW],
        value: INTERVIEW_STAGE.HIRING_MANAGER_INTERVIEW,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.TEAM_INTERVIEW],
        value: INTERVIEW_STAGE.TEAM_INTERVIEW,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.FINAL_INTERVIEW],
        value: INTERVIEW_STAGE.FINAL_INTERVIEW,
      },
    ],
  },
  {
    label: 'Reference Check',
    title: 'check',
    key: 'check',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.REFERENCE_CHECK],
        value: INTERVIEW_STAGE.REFERENCE_CHECK,
      },
    ],
  },
  {
    label: 'Offer',
    title: 'offer',
    key: 'offer',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.OFFER_EXTENDED],
        value: INTERVIEW_STAGE.OFFER_EXTENDED,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.BACKGROUND_CHECK],
        value: INTERVIEW_STAGE.BACKGROUND_CHECK,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.OFFER_ACCEPTED],
        value: INTERVIEW_STAGE.OFFER_ACCEPTED,
      },
    ],
  },
  {
    label: 'Contract',
    title: 'contract',
    key: 'contract',
    options: [
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.TERMS_AGREED],
        value: INTERVIEW_STAGE.TERMS_AGREED,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.BACKGROUND_CHECK],
        value: INTERVIEW_STAGE.BACKGROUND_CHECK,
      },
      {
        label: INTERVIEW_STAGE_LABELS[INTERVIEW_STAGE.CONTRACT_ACCEPTED],
        value: INTERVIEW_STAGE.CONTRACT_ACCEPTED,
      },
    ],
  },
];

export const getInterviewOptions = (isContract) => {
  if (isContract) {
    return ALL_INTERVIEW_STAGE_OPTIONS.filter(({title}) => title !== 'offer');
  }
  return ALL_INTERVIEW_STAGE_OPTIONS.filter(({title}) => title !== 'contract');
};

export const CANDIDATE_REJECT_REASON = {
  MORE_QUALIFIED_CANDIDATE_SELECTED: 'more_qualified_candidate_selected',
  DID_NOT_MEET_DESIRED_QUALIFICATIONS: 'did_not_meet_desired_qualifications',
  DID_NOT_MEET_MINIMUM_QUALIFICATIONS: 'did_not_meet_minimum_qualifications',
  DID_NOT_MEET_SCREENING_REQUIREMENTS: 'did_not_meet_screening_requirements',
  DID_NOT_FIT_COMPANY_CULTURE: 'did_not_fit_company_culture',
  MISREPRESENTED_QUALIFICATIONS: 'misrepresented_qualifications',
  INELIGIBLE_TO_WORK_IN_LOCATION: 'ineligible_to_work_in_location',
  INCOMPLETE_APPLICATION: 'incomplete_application',
  DUPLICATE: 'duplicate',
  SPAM: 'spam',
  NO_AVAILABLE_RESOURCES: 'no_available_resources',
  NO_LONGER_AVAILABLE: 'no_longer_available',
  NO_LONGER_NEEDED: 'no_longer_needed',
  NOT_INTERESTED: 'not_interested',
  INADEQUATE_COMPENSATION: 'inadequate_compensation',
  UNRESPONSIVE_TO_OUTREACH: 'unresponsive_to_outreach',
  NO_SHOW_FOR_INTERVIEW: 'no_show_for_interview',
  OTHER: 'other',
  NONE_SPECIFIED: 'none_specified',
  INQUIRY_CLOSED: 'inquiry_closed',
  SUBMISSION_CLOSED: 'submission_closed',
  REQUISITION_CLOSED: 'job_closed',
  REQUISITION_FILLED: 'job_filled',
};

export const CANDIDATE_REJECT_REASON_LABELS = Object.values(
  CANDIDATE_REJECT_REASON,
).reduce((acc, value) => {
  acc[value] = capitalize(value.replaceAll('_', ' '));
  return acc;
}, {});

const rejectCompanyOptions = [
  CANDIDATE_REJECT_REASON.MORE_QUALIFIED_CANDIDATE_SELECTED,
  CANDIDATE_REJECT_REASON.DID_NOT_MEET_DESIRED_QUALIFICATIONS,
  CANDIDATE_REJECT_REASON.DID_NOT_MEET_MINIMUM_QUALIFICATIONS,
  CANDIDATE_REJECT_REASON.DID_NOT_MEET_SCREENING_REQUIREMENTS,
  CANDIDATE_REJECT_REASON.DID_NOT_FIT_COMPANY_CULTURE,
  CANDIDATE_REJECT_REASON.MISREPRESENTED_QUALIFICATIONS,
  CANDIDATE_REJECT_REASON.INELIGIBLE_TO_WORK_IN_LOCATION,
  CANDIDATE_REJECT_REASON.INCOMPLETE_APPLICATION,
  CANDIDATE_REJECT_REASON.DUPLICATE,
  CANDIDATE_REJECT_REASON.SPAM,
];

const rejectCandidateOptions = [
  CANDIDATE_REJECT_REASON.NO_LONGER_AVAILABLE,
  CANDIDATE_REJECT_REASON.NOT_INTERESTED,
  CANDIDATE_REJECT_REASON.INADEQUATE_COMPENSATION,
  CANDIDATE_REJECT_REASON.UNRESPONSIVE_TO_OUTREACH,
  CANDIDATE_REJECT_REASON.NO_SHOW_FOR_INTERVIEW,
];
const otherCompanyOptions = [
  CANDIDATE_REJECT_REASON.OTHER,
  CANDIDATE_REJECT_REASON.NONE_SPECIFIED,
];

export const CANDIDATE_REJECT_REASON_OPTIONS = [
  {
    label: 'Candidate rejected company',
    title: 'company',
    key: 'company',
    options: rejectCompanyOptions.map((value) => ({
      value,
      label: CANDIDATE_REJECT_REASON_LABELS[value],
    })),
  },
  {
    label: 'Company rejected candidate',
    title: 'candidate',
    key: 'candidate',
    options: rejectCandidateOptions.map((value) => ({
      value,
      label: CANDIDATE_REJECT_REASON_LABELS[value],
    })),
  },

  {
    label: 'Other',
    title: 'other-title',
    key: 'title',
    options: otherCompanyOptions.map((value) => ({
      value,
      label: CANDIDATE_REJECT_REASON_LABELS[value],
    })),
  },
];

export const DECLINE_SUB_OPTIONS = [
  CANDIDATE_REJECT_REASON.NO_LONGER_NEEDED,
  CANDIDATE_REJECT_REASON.REQUISITION_FILLED,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));

export const WITHDRAW_OPTIONS = [
  CANDIDATE_REJECT_REASON.NOT_INTERESTED,
  CANDIDATE_REJECT_REASON.NO_LONGER_AVAILABLE,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));

export const WITHDRAW_OPTIONS_FROM_SUB_SUPPLIER = [
  CANDIDATE_REJECT_REASON.NO_LONGER_AVAILABLE,
  CANDIDATE_REJECT_REASON.NOT_INTERESTED,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));

export const WITHDRAW_SUB_OPTIONS = [
  CANDIDATE_REJECT_REASON.NO_LONGER_AVAILABLE,
  CANDIDATE_REJECT_REASON.NOT_INTERESTED,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));

export const DECLINE_INQ_OPTIONS = [
  CANDIDATE_REJECT_REASON.NO_AVAILABLE_RESOURCES,
  CANDIDATE_REJECT_REASON.NOT_INTERESTED,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));

export const WITHDRAW_INQ_OPTIONS = [
  CANDIDATE_REJECT_REASON.NO_LONGER_NEEDED,
  CANDIDATE_REJECT_REASON.REQUISITION_FILLED,
].map((value) => ({
  value,
  label: CANDIDATE_REJECT_REASON_LABELS[value],
}));
