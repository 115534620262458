import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {useGetReport} from 'app/services/clientService';
import MetricCard from 'app/shared/cards/MetricCard';
import PropTypes from 'prop-types';
import {
  RiBriefcaseFill,
  RiBuildingLine,
  RiFileTransferLine,
  RiGroupLine,
  RiQuestionnaireLine,
} from 'react-icons/ri';

const matrices = [
  {
    title: 'Open <br> Jobs',
    key: 'open_requisitions',
    icon: <RiBriefcaseFill fontSize={32} />,
  },
  {
    title: 'Total <br> Openings',
    key: 'total_openings',
    icon: <RiBriefcaseFill fontSize={32} />,
  },
  {
    title: 'Potential <br> Candidates',
    key: 'potential_candidates',
    icon: <RiGroupLine fontSize={32} />,
  },
  {
    title: 'Open <br> Inquiries',
    key: 'open_inquiries',
    icon: <RiQuestionnaireLine fontSize={32} />,
  },
  {
    title: 'Open <br> Submissions',
    key: 'open_submissions',
    icon: <RiFileTransferLine fontSize={32} />,
  },
  {
    title: 'Pending <br> Candidates',
    key: 'pending_candidates',
    icon: <RiGroupLine fontSize={32} />,
  },

  {
    title: 'Active <br> Suppliers',
    key: 'active_suppliers',
    icon: <RiBuildingLine fontSize={32} />,
  },
];

const Widgets = () => {
  const {data} = useGetReport();

  return (
    <AppGridContainer gutter={[18, 18]}>
      {matrices.map((item, index) => (
        <Col xs={12} md={8} key={index}>
          <MetricCard
            title={item.title}
            count={data ? data[item.key] : 0}
            icon={item.icon}
          />
        </Col>
      ))}
    </AppGridContainer>
  );
};

Widgets.propTypes = {
  data: PropTypes.object,
};

export default Widgets;
