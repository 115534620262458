import AppCard from '@wieldy/components/AppCard';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Button, Col, Form, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {
  useGetBehalfUser,
  useUpdateIntegration,
} from '../../../../../../../services/integrationSevice';
import {useCurrentUser} from '../../../../../../../store/features/user/userSlice';
import CopyInput from '../../common/copy-input';

const importOptions = [
  {
    value: true,
    label: 'Import all open jobs from Greenhouse',
  },
  {
    value: false,
    label: 'Import selected jobs from Greenhouse',
  },
];
const exportOptions = [
  {
    value: 'manually',
    label: 'Manually upload selected candidates only',
  },
  {
    value: 'on_interview_stage',
    label: 'Automatically upload all candidates upon reaching a preset stage',
  },
  {
    value: 'all',
    label: 'Automatically upload all candidates upon submission',
  },
];

export const getStages = (data) => {
  if (!data) {
    return [];
  }
  return Object.entries(data).map(([key, values]) => ({
    label: key,
    title: String(key).toLocaleLowerCase(),
    key: String(key).toLocaleLowerCase().replaceAll(' ', '_'),
    options: values.includes(key)
      ? (values || []).map((value, idx) => ({value: value, key: value + idx}))
      : [
          {value: key},
          ...(values || []).map((value, idx) => ({
            value: value,
            key: value + idx,
          })),
        ],
  }));
};

const GreenHouseSettings = ({data}) => {
  const {id} = useParams();
  const updateIntegration = useUpdateIntegration(id);
  const behalfUsers = useGetBehalfUser(id);
  const behalfUsersOptions = (behalfUsers?.data || []).map((el) => ({
    value: el.id,
    label: el.primary_email_address || el.name,
  }));

  const {integrations_secret, id: clientId} = useCurrentUser();

  const stageOptions = useMemo(() => {
    return getStages(data?.settings?.candidate_interview_stages);
  }, [data?.settings?.candidate_interview_stages]);

  const initialStage = useMemo(() => {
    if (data.settings.candidate_interview_stage_to_export) {
      const [key, value] = Object.entries(
        data.settings.candidate_interview_stage_to_export,
      )[0];
      return value ? value : key;
    }
    return undefined;
  }, [data?.settings?.candidate_interview_stage_to_export]);

  const [form] = Form.useForm();
  const uploadVal = Form.useWatch('exportCandidates', form);

  const webUrl = `${process.env.REACT_APP_BASE_URL}/webhooks/${clientId}`;
  const onUpdateKey = ({api_key}) => {
    const payload = {
      integration: {
        ...data.integration,
        apiKey: api_key,
      },
    };
    updateIntegration.mutate(payload);
  };

  const onUpdateImport = ({
    importAllJobs,
    exportCandidates,
    onBehalfUser,
    stageToExport,
  }) => {
    const stageElement = stageOptions.find(
      (el) => !!el.options.find((option) => option.value === stageToExport),
    );

    let stage;

    if (stageToExport && stageElement.label === stageToExport) {
      stage = {
        [stageToExport]: null,
      };
    } else if (stageToExport && stageElement.label !== stageToExport) {
      stage = {
        [stageElement.label]: stageToExport,
      };
    }

    const payload = {
      settings: {
        ...data.settings,
        importAllJobs,
        exportCandidates,
        onBehalfUser,
        candidate_interview_stage_to_export: stage,
      },
      onSync: !data.last_sync_at,
    };

    updateIntegration.mutate(payload);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={14}>
        <AppCard title='Integration Settings'>
          <Typography.Paragraph>
            Manage integration settings for synchronizing jobs and candidates
            between your accounts
          </Typography.Paragraph>
          <Form
            form={form}
            onFinish={onUpdateImport}
            layout='vertical'
            colon={false}
            initialValues={data.settings}>
            <Row gutter={20} className='mb-5'>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Field is required',
                    },
                  ]}
                  name='onBehalfUser'
                  label='Select ATS User to Assign as ‘On-Behalf-Of’ User'>
                  <AppSelect
                    style={{
                      width: '100%',
                    }}
                    placeholder='Select user'
                    options={behalfUsersOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20} className='mb-5'>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Field is required',
                    },
                  ]}
                  name='importAllJobs'
                  label='Import Jobs from ATS'>
                  <AppSelect
                    style={{
                      width: '100%',
                    }}
                    placeholder='Select import option'
                    options={importOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20} className='mb-5'>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Field is required',
                    },
                  ]}
                  name='exportCandidates'
                  label='Upload Candidates to ATS'>
                  <AppSelect
                    style={{
                      width: '100%',
                    }}
                    placeholder='Select upload option'
                    options={exportOptions}
                  />
                </Form.Item>
              </Col>
              {uploadVal === 'on_interview_stage' && (
                <Col span={12}>
                  <Form.Item
                    initialValue={initialStage}
                    rules={[
                      {
                        required: true,
                        message: 'Field is required',
                      },
                    ]}
                    name='stageToExport'
                    label='Interview Stage to Upload to ATS'>
                    <AppSelect
                      style={{
                        width: '100%',
                      }}
                      placeholder='Select export option'
                      options={stageOptions}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Button
              type='primary'
              htmlType='submit'
              disabled={updateIntegration.isLoading}>
              Save
            </Button>
          </Form>
        </AppCard>
      </Col>
      <Col span={10} className='d-flex flex-column flex-gap-6'>
        <AppCard title='API Key'>
          <Form
            onFinish={onUpdateKey}
            initialValues={{
              api_key: data.integration?.apiKey,
            }}
            layout='vertical'
            colon={false}>
            <Typography.Paragraph>
              If API key needs to be updated after the initial connection has
              been set up, enter key below and click ‘Reconnect’ to reestablish
              the connection
            </Typography.Paragraph>
            <Form.Item name='api_key' label='API key'>
              <AppInput />
            </Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={updateIntegration.isLoading}>
              Reconnect
            </Button>
          </Form>
        </AppCard>
        <AppCard title='Webhooks'>
          <Typography.Paragraph>
            Endpoint URL, Secret Key and Error Recipient Emails to set up
            webhooks
          </Typography.Paragraph>
          <CopyInput
            label='Endpoint URL'
            placeholder='Enter Endpoint URl'
            defValue={webUrl}
          />
          <CopyInput
            label='Secret Key'
            placeholder='Enter Secret Key'
            defValue={integrations_secret}
            type='password'
          />
          <CopyInput
            label='Error Recipient Email'
            placeholder='Enter Error Recipient Email'
            defValue='support+int+greenhouse@sourcer.com'
          />
        </AppCard>
      </Col>
    </Row>
  );
};

GreenHouseSettings.propTypes = {
  data: PropTypes.object,
};

export default GreenHouseSettings;
