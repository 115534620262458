export const getTabs = (isSupplier) => {
  const tabs = [
    {
      key: 'company',
      title: 'Company Information',
      route: '/administration/company',
    },
    {key: 'users', title: 'Users', route: '/administration/users'},
    {
      key: 'invite-users',
      title: 'Invitations',
      route: '/administration/invite-users',
    },
    {key: 'settings', title: 'Settings', route: '/administration/settings'},
    {key: 'reviews', title: 'Reviews', route: '/administration/reviews'},
  ];
  if (!isSupplier) {
    tabs.splice(4, 0, {
      key: 'integrations',
      title: 'Integrations',
      route: '/administration/integrations',
    });
  }
  if (isSupplier) {
    tabs.splice(1, 0, {
      key: 'resources',
      title: 'Resources',
      route: '/administration/resources',
    });
  }
  return tabs;
};
