import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';

import {CATEGORIES, USER_INCLUDES} from '../../../constants';

export const statuses = [
  {status: CATEGORIES.PENDING, sort: ['-created_at']},
  {status: CATEGORIES.ACTIVE, sort: ['-status_active_date']},
  {status: CATEGORIES.COMPLETED, sort: ['-status_active_end_date']},
];
export const categories = ['all', ...statuses.map(({status}) => status)];

export const getPageTabsData = (isNotes) => {
  const tabsData = [
    {key: 'summary', title: 'Summary'},
    {key: 'client', title: 'Client'},
    {key: 'requisition', title: 'Job'},
    {key: 'rates', title: 'Rates'},
    {key: 'tasks', title: 'Tasks'},
    {key: 'time-cards', title: 'TimeCards'},
    {key: 'expenses', title: 'Expenses'},
    {key: 'spending-summary', title: 'Spending Summary'},
  ];

  isNotes && tabsData.push({key: 'notes', title: 'notes'});
  return tabsData;
};

export const tabsData = categories.map((key) => ({
  key,
  title: capitalizedString(key),
  route: `/engagements/${key}`,
}));

export const commonQueryParams = {};

const supplierQueryParams = {
  ...commonQueryParams,
  include: ['total_count', ...USER_INCLUDES.clientIncludeDetails],
  expand: ['client', 'resource', 'requisition'],
};

const clientQueryParams = {
  ...commonQueryParams,
  include: ['total_count', ...USER_INCLUDES.supplierIncludeDetails],
  expand: ['supplier', 'resource', 'requisition'],
};

export const getEngagementsQueryParams = (isSupplier) => {
  return isSupplier ? supplierQueryParams : clientQueryParams;
};

export const getPageParams = (isSupplier = false) => {
  const queryParams = getEngagementsQueryParams(isSupplier);
  return {
    all: {
      queryParams,
      title: 'All Engagements',
    },
    pending: {
      queryParams: {
        ...queryParams,
        status: 'pending',
      },
      title: 'Pending Engagements',
      label: 'Created',
      rowTitle: 'Pending Engagements',
      empty: 'No Pending Engagements',
    },
    active: {
      queryParams: {
        ...queryParams,
        status: 'active',
      },
      title: 'Active Engagements',
      label: 'Activated',
      rowTitle: 'Active Engagements',
      empty: 'No Active Engagements',
    },
    completed: {
      queryParams: {
        ...queryParams,
        status: 'completed',
      },
      title: 'Completed Engagements',
      label: 'Completed',
      rowTitle: 'Completed Engagements',
      empty: 'No Completed Engagements',
    },
  };
};

export const sortParams = {
  pending: [
    {label: 'Created date (newest first)', value: '-created_at'},
    {label: 'Created date (oldest first)', value: '+created_at'},
  ],
  active: [
    {
      label: 'Activated date (newest first)',
      value: '-status_active_date',
    },
    {
      label: 'Activated date (oldest first)',
      value: '+status_active_date',
    },
  ],
  completed: [
    {label: 'Completed date (newest first)', value: '-status_active_end_date'},
    {label: 'Completed date (oldest first)', value: '+status_active_end_date'},
  ],
};
