import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {Tooltip, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {
  CANDIDATE_STATUSES,
  CANDIDATE_STATUSES_LABEL,
} from 'app/constants/candidate-statuses.js';
import {INQUIRY_STATUSES} from 'app/constants/inquiry-statuses.js';
import CandidateModal from 'app/modals/CandidateModal';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {
  formatDate,
  getAddress,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import {insert} from 'app/utils/helpers/Insert.js';
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';

const supplier = {
  title: 'Supplier',
  dataIndex: 'role',
  key: 'role',
  render: (_, {supplier}) => {
    return (
      <Link
        to={`/suppliers/${supplier?.id}/view-supplier`}
        component={Typography.Link}>
        {getUserName(supplier?.name, 'SUP-', 'Supplier')}
      </Link>
    );
  },
};

export const getColumns = (isClient, isHideSupplier, isHideBilling) => {
  const billing = {
    title: 'Billing Rate',
    dataIndex: 'billingRateLabel',
    key: 'billingRateLabel',
    width: 140,
    render: (_, {candidates}) => {
      const cand = candidates?.[0];
      const candidate = new CandidateModal(cand);
      return <Typography.Text>{candidate.billingRateLabel}</Typography.Text>;
    },
  };
  const defaultArray = [
    {
      title: 'Inquiry',
      dataIndex: 'title',
      key: 'title',
      render: (_, {id, hid, requisition}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Link
              to={`/inquiries/${id}/view-inquiry`}
              component={Typography.Link}>
              {requisition?.job_title}
            </Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {hid}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Inquiry Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Typography.Text>
          {value
            ? INQUIRY_STATUSES[value] ||
              capitalizedAllWords(value.replace('_', ' '))
            : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Inquired On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => (
        <Typography.Text>
          {formatDate(created_at, DATE_FORMATS.md)}
        </Typography.Text>
      ),
    },
    {
      title: 'Candidate Name / Location',
      dataIndex: 'candidate',
      key: 'candidate',
      width: 240,
      render: (_, {candidates}) => {
        const candidate = candidates?.[0];
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${
                  candidate?.id || candidate?.candidate_id
                }/view-candidate`}
                component={Typography.Link}>
                {getFullName(candidate?.resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(candidate?.resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      width: 200,
      render: (_, {candidates}) => {
        const cand = candidates?.[0];
        return (
          <Typography.Text>
            {formatDate(cand?.available_on, DATE_FORMATS.md) || 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Candidate Status',
      dataIndex: 'candidate_status',
      key: 'candidate_status',
      width: 200,
      render: (_, {candidates}) => {
        const cand = candidates?.[0];
        if (!cand) {
          return;
        }
        const isRejected = cand.status_key === CANDIDATE_STATUSES.REJECTED;
        const isShowConfirm =
          cand.isConfirmed && cand.status_key === CANDIDATE_STATUSES.NEW;

        return (
          <div className='d-flex flex-column align-items-start'>
            <Typography.Text className={'text-sm mb-0'}>
              {CANDIDATE_STATUSES_LABEL[cand.status_key] ?? cand.status}
              {isRejected && (
                <Tooltip title={cand?.rejectReasonLabel} className='ml-2'>
                  <AiOutlineInfoCircle style={{height: 16, width: 16}} />
                </Tooltip>
              )}
            </Typography.Text>
            {isShowConfirm && <Typography.Text>(Confirmed)</Typography.Text>}
            {isRejected ||
              (cand.interviewStageLabel && (
                <Typography.Text>({cand.interviewStageLabel})</Typography.Text>
              ))}
          </div>
        );
      },
    },
  ];

  const array =
    isClient && !isHideSupplier
      ? insert(defaultArray, 3, supplier)
      : defaultArray;
  return isHideBilling
    ? array
    : insert(array, !isHideSupplier ? 5 : 4, billing);
};

export const getExpandedCols = (isHideBilling) => {
  const billing = {
    title: 'Billing Rate',
    dataIndex: 'billingRateLabel',
    key: 'billingRateLabel',
    width: 140,
    render: (_, cand) => {
      const candidate = new CandidateModal(cand);
      return <Typography.Text>{candidate.billingRateLabel}</Typography.Text>;
    },
  };
  const array = [
    {},
    {},
    {},
    {
      title: 'Candidate Name / Location',
      dataIndex: 'candidate',
      key: 'candidate',
      width: 240,
      render: (_, candidate) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${
                  candidate?.id || candidate?.candidate_id
                }/view-candidate`}
                component={Typography.Link}>
                {getFullName(candidate?.resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(candidate?.resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      width: 200,
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
    {
      title: 'Candidate Status',
      dataIndex: 'candidate_status',
      key: 'status_key',
      width: 200,
      render: (
        value,
        {status_key, rejectReasonLabel, isConfirmed, interviewStageLabel},
      ) => {
        const isRejected = status_key === CANDIDATE_STATUSES.REJECTED;
        const isShowConfirm =
          isConfirmed && status_key === CANDIDATE_STATUSES.NEW;

        return (
          <div className='d-flex flex-column align-items-start'>
            <Typography.Text className={'text-sm mb-0'}>
              {CANDIDATE_STATUSES_LABEL[status_key] ?? status_key}
              {isRejected && (
                <Tooltip title={rejectReasonLabel} className='ml-2'>
                  <AiOutlineInfoCircle style={{height: 16, width: 16}} />
                </Tooltip>
              )}
            </Typography.Text>
            {isShowConfirm && <Typography.Text>(Confirmed)</Typography.Text>}
            {isRejected ||
              (interviewStageLabel && (
                <Typography.Text>({interviewStageLabel})</Typography.Text>
              ))}
          </div>
        );
      },
    },
  ];
  return isHideBilling ? array : insert(array, 4, billing);
};
