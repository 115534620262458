import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col, notification, Space} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType.js';
import {useUpdateRequisition} from 'app/services/requisitionService.js';
import CancelPopup from 'app/shared/components/CancelPopup/CancelPopup';
import Notes from 'app/shared/components/Notes/Notes';
import Candidates from 'app/shared/components/Tables/Candidates';
import Inquiries from 'app/shared/components/Tables/Inquiries';
import Submissions from 'app/shared/components/Tables/Submissions';
import {RequisitionContentCard} from 'app/shared/requisitions';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {getQueryParams, TABS} from '../constatns';
import ClientDetailCard from './ClientDetailCard';
//import SubmissionCard from 'app/shared/cards/SubmissionCard';

const MainContent = ({
  requisition,
  isShowNotes,
  notesResponse,
  notesTotal,
  handleSetTabView,
  tabView,
}) => {
  const navigate = useNavigate();
  const {isClient, isSupplier} = useAccountType();
  const mutation = useUpdateRequisition(requisition?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleOpen = useCallback((isOpen) => {
    setIsModalOpen(isOpen);
  }, []);

  const {isInquiries, isSubmissions, isCandidates} = tabView;
  const {id, isDirectHire} = requisition;

  const handleCloseReq = React.useCallback(
    (comment, status) => {
      if (isClient && mutation) {
        mutation.mutate(
          {
            status,
            close_reason: comment,
          },
          {
            onSuccess: () => {
              notification.success({
                message: 'Job closed successfully',
              });
              navigate(`/requisitions/${status}`);
            },
          },
        );
      }
    },
    [isClient, mutation, navigate],
  );

  const cardGridColumns = isClient ? 24 : 16;

  return (
    <AppGridContainer>
      <Col xs={24} sm={cardGridColumns} lg={cardGridColumns}>
        <RequisitionContentCard requisition={requisition} showNotes={true}>
          {['open', 'imported'].includes(requisition?.status) && isClient && (
            <Space size='middle' wrap className='mt-6'>
              <Button
                type='primary'
                onClick={() =>
                  navigate(`/requisitions/${requisition.id}/edit-requisition`)
                }>
                Edit Job
              </Button>
              <Button
                type='primary'
                loading={mutation?.isLoading}
                ghost={true}
                onClick={() => toggleOpen(true)}>
                Close Job
              </Button>
            </Space>
          )}
        </RequisitionContentCard>
      </Col>
      {isSupplier && (
        <Col xs={24} sm={24} md={8} lg={8} id='client'>
          <ClientDetailCard client={requisition?.client} />
        </Col>
      )}
      <Col xs={24} sm={24} md={24} lg={24} id='inquiry' hidden={!isInquiries}>
        <Inquiries
          defaultQueryParams={getQueryParams(TABS.INQUIRIES, id)}
          isShow={isInquiries}
          handleSetTabView={handleSetTabView}
          isOne={isSupplier}
        />
      </Col>
      <Col xs={24} id='submission' hidden={!isSubmissions}>
        <Submissions
          defaultQueryParams={getQueryParams(TABS.SUBMISSIONS, id)}
          isShow={isSubmissions}
          handleSetTabView={handleSetTabView}
          isOne={isSupplier}
          isDirectHire={isDirectHire}
        />
      </Col>
      <Col xs={24} id='candidates' hidden={!isCandidates}>
        <Candidates
          defaultQueryParams={getQueryParams(TABS.CANDIDATES, id)}
          isShow={isCandidates}
          handleSetTabView={handleSetTabView}
          isDirectHire={isDirectHire}
        />
      </Col>
      {isShowNotes && (
        <Col sm={24} id='notes'>
          <Notes
            entity={'requisition_notes'}
            primaryKey={'requisition'}
            primaryId={requisition?.id}
            notesResponse={notesResponse}
            notesTotal={notesTotal}
          />
        </Col>
      )}
      <CancelPopup
        handleReject={handleCloseReq}
        isModalOpen={isModalOpen}
        toggleOpen={toggleOpen}
        loading={mutation?.isLoading}
      />
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  requisition: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
  handleSetTabView: PropTypes.func,
  tabView: PropTypes.object,
};

export default MainContent;
