import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, DatePicker, Form, Radio} from 'antd';
import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_OPTIONS,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import useStep from 'app/hooks/useStep';
import {CompanyUserDropdown} from 'app/shared/components/dropdowns';
import {setPlacementDetails} from 'app/store/features/placement/placementSlice';
import {getOptionsFromObject} from 'app/utils/helpers/GetOptionsFromObject';
import {allowPositiveExactNumber} from 'app/utils/helpers/preventNegativeNumber';
import {
  feeFixedValidator,
  feePercentageValidator,
} from 'app/utils/helpers/RecruitmentFeeValidators';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {WORK_TYPE_KEYS} from '../../../../../../constants/work-types';
import {getProcessedDate} from '../../../../../../utils/helpers/DataFormatHelper';
import {isValidDateRange} from '../../../../../../utils/helpers/IsValidDateRange';

const RangePicker = DatePicker.RangePicker;

const salaryValidator = (_, value) => {
  if (!value) return Promise.resolve();
  if (value < 1 || value > 1000000)
    return Promise.reject('The salary out of the range $1 to $1,000,000');

  return Promise.resolve();
};

const PlacementDetailsForm = () => {
  const {nextStep} = useStep();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {isMarketplace} = useAccountType();
  const {isMarketManager, isAdmin} = useRole();
  const isShowButton = isMarketplace && (isMarketManager || isAdmin);

  const feeType = Form.useWatch('recruitment_fee_structure', form);

  const {details, requisition} = useSelector(({placement}) => placement);
  const {user} = useSelector(({user}) => user);

  const isContract = requisition.type === WORK_TYPE_KEYS.contract;
  const isTemporary = requisition.type === WORK_TYPE_KEYS.temporary;

  const [manager, setManager] = React.useState({
    ...details.hiring_manager,
    name: `${details.hiring_manager?.first_name} ${details.hiring_manager?.last_name}`,
  });
  const [accountManager, setAccountManager] = React.useState(
    details.account_manager
      ? {
          ...details.account_manager,
          name: `${details.account_manager?.first_name} ${details.account_manager?.last_name}`,
        }
      : {...user, name: `${user?.first_name} ${user?.last_name}`},
  );
  const [resourceManager, setResourceManager] = React.useState(
    details.resource_manager,
  );

  const handleChangeManager = React.useCallback((_, option) => {
    setManager(option);
  }, []);

  const handleChangeAccountManager = React.useCallback((_, option) => {
    setAccountManager(option);
  }, []);

  const handleChangeHiringManager = React.useCallback((_, option) => {
    setResourceManager(option);
  }, []);

  const startDate = getProcessedDate(details?.start_date);
  const endDate = getProcessedDate(details?.end_date);
  const reqStartDate = getProcessedDate(requisition?.start_date);
  const reqEndDate = getProcessedDate(requisition?.end_date);

  const rangeOfDate = Form.useWatch('start_end_date', form);
  const [currentStart, currentEnd] = rangeOfDate || [];

  const diff = currentEnd ? currentEnd.diff(currentStart, 'days') : null;

  const baseKey = isContract || isTemporary ? 'billing_rate' : 'base_salary';

  const baseCount = Form.useWatch(baseKey, form);
  const base = isContract || isTemporary ? baseCount * 2080 : baseCount;

  const initialValues = useMemo(() => {
    const defaultInit = {
      hiring_manager: details.hiring_manager?.id,
      account_manager: details.account_manager?.id || user.id,
      resource_manager:
        details.resource_manager?.id || details.resource_manager,
      guarantee_period:
        details.guarantee_period || requisition.guarantee_period,
      guarantee_period_action:
        details.guarantee_period_action || requisition.guarantee_period_action,
      recruitment_fee_structure:
        details.recruitment_fee_structure ||
        requisition.recruitment_fee_structure ||
        RECRUITMENT_FEE_STRUCTURE_KEYS.percentage_of_salary,
      recruitment_fee: details.recruitment_fee || requisition.recruitment_fee,
      base_salary: details.base_salary || null,
      billing_rate: details.billing_rate || null,
    };
    if (isContract) {
      defaultInit.start_end_date =
        startDate && endDate
          ? [startDate, endDate]
          : [reqStartDate, reqEndDate];
    } else {
      defaultInit.start_date = startDate || reqStartDate;
    }
    return defaultInit;
  }, [
    details.account_manager?.id,
    details.base_salary,
    details.billing_rate,
    details.guarantee_period,
    details.guarantee_period_action,
    details.hiring_manager?.id,
    details.recruitment_fee,
    details.recruitment_fee_structure,
    details.resource_manager,
    endDate,
    isContract,
    reqEndDate,
    reqStartDate,
    requisition.guarantee_period,
    requisition.guarantee_period_action,
    requisition.recruitment_fee,
    requisition.recruitment_fee_structure,
    startDate,
    user.id,
  ]);

  const handleOnSubmit = (formData) => {
    const {start_date, start_end_date, ...rest} = formData;

    const date = start_end_date
      ? {
          start_date: start_end_date[0].unix(),
          end_date: start_end_date[1].unix(),
        }
      : {
          start_date: start_date.unix(),
        };

    const payload = {
      placement_supplier_id: details.placement_supplier_id,
      ...rest,
      ...date,
      hiring_manager: manager,
      account_manager: accountManager,
      resource_manager: resourceManager,
    };
    dispatch(setPlacementDetails(payload));

    if (nextStep) {
      nextStep();
    }
  };

  const getValueSupplier = (users) => {
    if (typeof details.resource_manager === 'string') {
      setResourceManager(
        users.find((item) => item.id === details.resource_manager),
      );
    }
  };

  const handleChangeFeeType = React.useCallback(() => {
    form.setFieldValue('recruitment_fee', undefined);
  }, [form]);

  const isPercent = feeType === 'percentage_of_salary';

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          form={form}
          onFinish={handleOnSubmit}
          initialValues={initialValues}>
          <AppFormBody>
            {isShowButton && (
              <>
                <Form.Item
                  name='hiring_manager'
                  label='Hiring Manager'
                  rules={[
                    {
                      message: getRequiredMessage('Hiring Manager'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    allowClear
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    placeholder='Search for hiring manager'
                    variant='client'
                    initialFetch
                    role='hiring_manager'
                    manager={manager}
                    onChange={handleChangeManager}
                  />
                </Form.Item>

                <Form.Item
                  name='resource_manager'
                  label='Supplier Contact'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Supplier Contact'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    initialFetch
                    placeholder='Search for supplier contact'
                    variant='supplier_all'
                    role='client_marketplace'
                    getValue={getValueSupplier}
                    source='placement'
                    filterMembers={(item) => item?.role !== 'resource'}
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    manager={resourceManager}
                    onChange={handleChangeHiringManager}
                  />
                </Form.Item>

                <Form.Item
                  name='account_manager'
                  label='Account Manager'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Account Manager'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    placeholder='Search for account manager'
                    variant='marketplaceAll'
                    manager={accountManager}
                    initialFetch
                    role={'account_manager'}
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    z
                    onChange={handleChangeAccountManager}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              style={{maxWidth: '320px'}}
              label={
                isContract || isTemporary
                  ? 'Hourly Rate Offer'
                  : 'Base Salary Offer'
              }
              name={isContract || isTemporary ? 'billing_rate' : 'base_salary'}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage(
                    isContract || isTemporary ? 'Rate' : 'Salary',
                  ),
                },
                {
                  validator: salaryValidator,
                },
              ]}>
              <AppInputNumber
                onKeyDown={allowPositiveExactNumber}
                placeholder='Enter amount'
                prefix='$'
                addonAfter='USD'
                type='number'
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                style={{width: '100%'}}
                controls={false}
              />
            </Form.Item>
            {isContract ? (
              <Form.Item
                label={'Start Date - End Date'}
                name='start_end_date'
                rules={[
                  {
                    required: true,
                    validator: isValidDateRange(
                      getRequiredMessage('Start & End date'),
                    ),
                  },
                ]}>
                <RangePicker
                  style={{width: '100%', maxWidth: 280}}
                  format='MM-DD-YYYY'
                  disabledDate={(current) =>
                    current && current < moment().startOf('day')
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item
                label='Job Start Date'
                name='start_date'
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <AppDatePicker
                  placeholder={getSelectPlaceHolder('job start date')}
                  style={{width: '100%', maxWidth: 280}}
                  disabledDate={(currentDate) => {
                    return moment().isAfter(moment(currentDate).add(1, 'days'));
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              style={{maxWidth: '320px'}}
              name='guarantee_period'
              label='Guarantee Period'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee period'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Select period')}
                options={getOptionsFromObject(GUARANTEE_PERIOD)}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              style={{maxWidth: '320px'}}
              name='guarantee_period_action'
              label='Guarantee Action'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee Action'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Select action')}
                options={getOptionsFromObject(GUARANTEE_PERIOD_ACTION)}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              label='Recruitment Fee Structure'
              name='recruitment_fee_structure'
              style={{marginBottom: 2}}
              labelCol={{style: {paddingBottom: 0}}}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Radio.Group
                options={RECRUITMENT_FEE_OPTIONS}
                onChange={handleChangeFeeType}
              />
            </Form.Item>
            <Form.Item
              name='recruitment_fee'
              style={{width: '320px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Fee'),
                },
                {
                  validator: isPercent
                    ? feePercentageValidator
                    : feeFixedValidator({
                        diff,
                        baseSalary: base,
                      }),
                },
              ]}>
              <AppInputNumber
                placeholder={isPercent ? 'Enter percentage' : 'Enter amount'}
                prefix={isPercent ? '' : '$'}
                addonAfter={isPercent ? '%' : 'USD'}
                type='number'
                onKeyDown={allowPositiveExactNumber}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                style={{width: '100%'}}
                controls={false}
              />
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

PlacementDetailsForm.propTypes = {
  placement: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default PlacementDetailsForm;
