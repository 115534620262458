import axios from 'app/config/axios';
import {USER_CREDENTIALS} from 'app/constants/user-creds';

export const mapUserAccountProps = (data = {}) => {
  const {clients, suppliers, resources, user, marketplace} = data;

  if (!user) {
    throw new Error('User must be required');
  }
  const accounts = [];
  if (marketplace) {
    accounts.push({
      data: {
        [USER_CREDENTIALS.USER_TYPES.marketplace]: {
          ...marketplace,
        },
      },
      id: marketplace.id,
      header: null,
      role: marketplace.role,
      accountType: USER_CREDENTIALS.USER_TYPES.marketplace,
    });
  }
  if (Array.isArray(suppliers) && suppliers.length > 0) {
    if (suppliers[0].membership.role === USER_CREDENTIALS.USER_ROLES.resource) {
      if (Array.isArray(resources) && resources.length > 0) {
        accounts.push({
          data: {
            resource: {
              ...resources[0],
            },
          },
          id: resources[0]?.id,
          header: 'sourcer-resource',
          role: suppliers[0]?.membership?.role,
          accountType: USER_CREDENTIALS.USER_TYPES.supplier,
        });
      } else {
        accounts.push({
          data: suppliers[0],
          id: suppliers[0]?.[USER_CREDENTIALS.USER_TYPES.supplier].id,
          header: 'sourcer-supplier',
          role: suppliers[0]?.membership?.role,
          accountType: USER_CREDENTIALS.USER_TYPES.supplier,
        });
      }
    } else {
      accounts.push({
        data: suppliers[0],
        id: suppliers[0]?.[USER_CREDENTIALS.USER_TYPES.supplier].id,
        header: 'sourcer-supplier',
        role: suppliers[0]?.membership?.role,
        accountType: USER_CREDENTIALS.USER_TYPES.supplier,
      });
    }
  }
  if (Array.isArray(clients) && clients.length > 0) {
    accounts.push({
      data: clients[0],
      header: 'sourcer-client',
      id: clients[0]?.[USER_CREDENTIALS.USER_TYPES.client].id,
      role: clients[0]?.membership?.role,
      accountType: USER_CREDENTIALS.USER_TYPES.client,
    });
  }

  return {
    user: user,
    accounts,
    marketplace: data?.marketplace ?? null,
  };
};

export const getCurrentClient = async () => {
  const params = {
    expand: ['company_representative', 'account_manager'],
    include: ['integration'],
  };
  const {data} = await axios.get('current_client', {params});
  return data;
};

export const getCurrentSupplier = async () => {
  const params = {
    expand: ['company_representative', 'account_manager'],
  };
  const {data} = await axios.get('current_supplier', {params});
  return data;
};

export const getCurrentResource = async () => {
  const params = {
    expand: ['company_representative', 'account_manager'],
  };
  const {data} = await axios.get('current_resource', {params});
  return data;
};

export const getFetcher = {
  ['sourcer-resource']: {
    fetcher: getCurrentResource,
    accountType: USER_CREDENTIALS.USER_TYPES.supplier,
  },
  ['sourcer-supplier']: {
    fetcher: getCurrentSupplier,
    accountType: USER_CREDENTIALS.USER_TYPES.supplier,
  },
  ['sourcer-client']: {
    fetcher: getCurrentClient,
    accountType: USER_CREDENTIALS.USER_TYPES.client,
  },
};
