import {Button, Dropdown, Menu, Tooltip, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {formatDate} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {RiMore2Fill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {PERIODS} from '../../../../../../constants';
import {
  CANDIDATE_STATUSES,
  CANDIDATE_STATUSES_LABEL,
} from '../../../../../../constants/candidate-statuses';
import {useAccountType} from '../../../../../../hooks/useAccountType';

export const getInquiryCandidateLabel = (status) => {
  switch (status) {
    case 'available':
      return 'Update to Not Available';
    case 'not_available':
      return 'Update to Available';
    default:
      return 'Withdraw Candidate';
  }
};

export const useGetColumns = ({onOpenModal}) => {
  const navigate = useNavigate();
  const {isSupplier, isClient} = useAccountType();

  const handleSinglePageView = (id) => {
    navigate(`/candidates/${id}/view-candidate`);
  };

  const actions = {
    title: '',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 64,
    render: (_, {status_key, id, isConfirmed}) => {
      const title = isSupplier
        ? getInquiryCandidateLabel(status_key)
        : `Withdraw Candidate`;

      const isChangeStatus = isClient && isConfirmed;
      const isConfirmCandidate = isSupplier && !isConfirmed;
      const isShowWithdraw = isClient ? !isConfirmed : isConfirmed;

      return (
        <Dropdown
          overlay={
            <Menu>
              {isConfirmCandidate && (
                <Menu.Item
                  key='confirm'
                  title='Confirm Candidate'
                  onClick={onOpenModal(id, 'confirm')}>
                  Confirm Candidate
                </Menu.Item>
              )}
              {isChangeStatus && (
                <Menu.Item
                  key='status'
                  title='Change Status / Stage'
                  onClick={onOpenModal(id, 'status')}>
                  Change Status / Stage
                </Menu.Item>
              )}
              {isShowWithdraw && (
                <Menu.Item
                  key='withdraw'
                  title={title}
                  onClick={onOpenModal(id, 'withdraw')}>
                  Withdraw Candidate
                </Menu.Item>
              )}
            </Menu>
          }
          placement='bottomRight'
          disabled={
            [CANDIDATE_STATUSES.HIRED, CANDIDATE_STATUSES.ENGAGED].includes(
              status_key,
            ) ||
            (status_key === CANDIDATE_STATUSES.REJECTED && isSupplier)
          }
          trigger='hover'>
          <Button
            type='text'
            shape='circle'
            size='small'
            icon={<RiMore2Fill className={'text-xl'} />}
          />
        </Dropdown>
      );
    },
  };

  const base = [
    {
      title: 'Name / Location',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <React.Fragment>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              <Typography.Link onClick={() => handleSinglePageView(item.id)}>
                {item.name}
              </Typography.Link>
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </React.Fragment>
        );
      },
    },
    {
      title: 'Billing rate',
      dataIndex: 'billingRate',
      key: 'billing_rate',
      editable: true,
      render: (rate, {billing_rate_period}) => {
        return (
          <Typography.Text style={{width: 100, display: 'inline-block'}}>
            {rate}
            {PERIODS[billing_rate_period]
              ? ` / ${PERIODS[billing_rate_period]}`
              : ''}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available on',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (value) => {
        return (
          <Typography.Text style={{width: 120, display: 'inline-block'}}>
            {formatDate(value, DATE_FORMATS.md) || 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Inquired On',
      dataIndex: 'inquired_on',
      key: 'inquired_on',
      render: (value) => formatDate(value, DATE_FORMATS.md),
    },
    {
      title: 'Status / Stage',
      dataIndex: 'status',
      key: 'status',
      render: (
        status,
        {rejectReasonLabel, status_key, isConfirmed, interviewStageLabel},
      ) => {
        const isRejected = status_key === CANDIDATE_STATUSES.REJECTED;
        const isShowConfirm =
          isConfirmed && status_key === CANDIDATE_STATUSES.NEW;

        return (
          <div className='d-flex flex-column align-items-start'>
            <Typography.Text className={'text-sm mb-0'}>
              {CANDIDATE_STATUSES_LABEL[status_key] ?? status}
              {isRejected && (
                <Tooltip title={rejectReasonLabel} className='ml-2'>
                  <AiOutlineInfoCircle style={{height: 16, width: 16}} />
                </Tooltip>
              )}
            </Typography.Text>
            {isShowConfirm && <Typography.Text>(Confirmed)</Typography.Text>}
            {isRejected ||
              (interviewStageLabel && (
                <Typography.Text>({interviewStageLabel})</Typography.Text>
              ))}
          </div>
        );
      },
    },
  ];

  return [...base, actions];
};
