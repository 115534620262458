import Label from '@register-app/components/additional/label';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {Form, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const FirstStep = ({data}) => {
  return (
    <>
      <Typography.Title level={4}>Before You Start</Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        To set up the integration, <b>you must be a Greenhouse administrator</b>{' '}
        AND have the <b>Can manage ALL organization&apos;s API Credentials</b>{' '}
        developer permission, which allows you to generate an API key. If you
        are a site admin, follow the setup instructions below.
      </Typography.Paragraph>
      <Typography.Title level={4}>Step 1: Provide API Key</Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        Follow the instructions below and provide the API credentials needed to
        connect your Greenhouse and Sourcer accounts.
      </Typography.Paragraph>
      <Typography.Title level={5}>API Key Instructions</Typography.Title>
      <ol style={{paddingLeft: 14, marginBottom: 10}}>
        <li>
          Click the <b>Configure</b> icon on your Greenhouse application&apos;s
          navigation bar.
        </li>
        <li>
          On the left navigation menu, click on <b>Dev Center</b> section and
          select <b>API Credential Management</b> link.
        </li>
        <li>
          Click <b>Create new API credentials</b>.
        </li>
        <li>
          Select &apos;Harvest&apos; as <b>API type</b>, select
          &apos;Sourcer&apos; as <b>Partner</b> and type &apos;Sourcer Harvest
          API key&apos; as <b>Description</b>.
        </li>
        <li>
          When finished, click <b>Manage permissions</b> to generate the Harvest
          API key.
        </li>
        <li>
          Click <b>Copy</b> to save your API key to your clipboard, then paste
          it in <b>Greenhouse API Key</b> field below.
        </li>
      </ol>
      <div style={{maxWidth: 500, marginBottom: 30}}>
        <Label text='API Key' />
        <Form.Item
          initialValue={data?.integration?.apiKey}
          name='api_key'
          rules={[
            {
              required: true,
              message: 'Field is required',
            },
          ]}>
          <AppInput readOnly={data?.isActive} placeholder='Enter API key' />
        </Form.Item>
      </div>
      <p>
        7. Assign the permissions listed below by selecting the appropriate
        checkboxes on Greenhouse and click <b>Save</b>.
      </p>
      <Typography.Title level={5}>Activity Feed</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Applications</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Candidates</Typography.Title>
      <p className='mb-0'>* DELETE: Delete Candidate</p>
      <p className='mb-0'>* GET: Retrieve Candidate</p>
      <p className='mb-0'>* GET: List Candidates</p>
      <p className='mb-0'>* PATCH: Edit Candidate</p>
      <p className='mb-0'>* POST: Add Candidate</p>
      <p className='mb-0'>* POST: Add E-mail</p>
      <p className='mb-0'>* POST: Add Note</p>
      <p className='mb-0'>* POST: Add Attachment</p>
      <p className='mb-0'>* POST: Add Application</p>
      <p className='mb-0'>* POST: Add Education</p>
      <p className='mb-0'>* POST: Add Employment</p>
      <p className='mb-5'>* POST: Add Prospect</p>
      <Typography.Title level={5}>Close Reasons</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Job Posts</Typography.Title>
      <p className='mb-0'>* GET: List Job Posts</p>
      <p className='mb-0'>* GET: Retrieve Job Post for Job</p>
      <p className='mb-5'>* GET: List Job Posts for Job</p>
      <Typography.Title level={5}>Jobs</Typography.Title>
      <p className='mb-0'>* GET: Retrieve Job</p>
      <p className='mb-5'>* GET: List Jobs</p>
      <Typography.Title level={5}>Job Stages</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Offers</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Rejection Reasons</Typography.Title>
      <p className='mb-5'>* All permissions</p>
      <Typography.Title level={5}>Scheduled Interviews</Typography.Title>
      <p className='mb-0'>* GET: List Interviews for Application</p>
      <p className='mb-0'>* GET: Retrieve Interview</p>
      <p className='mb-5'>* GET: List Interviews</p>
      <Typography.Title level={5}>Users</Typography.Title>
      <p className='mb-0'>* GET: List Users</p>
      <p className='mb-5'>* GET: Retrieve User</p>
    </>
  );
};
FirstStep.propTypes = {
  data: PropTypes.object,
};
export default FirstStep;
