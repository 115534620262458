import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {
  getInputPlaceHolder,
  getRequiredMessage,
} from '@wieldy/utils/helpers/MessageHelper';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Space,
} from 'antd';
import {useSubmitTableForm} from 'app/pages/main/resources/hooks/useSubmitTableForm';
import Editor from 'app/shared/components/Editor/Editor';
import EmploymentHistory from 'app/shared/resource/EmploymentHistory';
import {
  addHistory,
  editHistory,
  removeHistory,
} from 'app/store/features/resource/resourceSlice';
import {isJson} from 'app/utils/helpers/isJson';
import {isUndefined} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const RangePicker = DatePicker.RangePicker;

const EmploymentForm = ({onContinue}) => {
  const {histories} = useSelector(({resource}) => resource);

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  const {
    form,
    isEnableAddButton,
    isSomeValue,
    isEdit,
    bulkActions,
    handleSubmit,
    handleResetForm,
    watcher,
  } = useSubmitTableForm(
    [
      'title',
      'company',
      'location',
      'start_end_dates',
      'currently_working_here',
      'description',
    ],
    histories,
    (el) => {
      return {
        title: el.title,
        company: el.company,
        location: el.location,
        start_end_dates: [
          moment(el.start_date).unix(),
          moment(el.end_date).unix(),
        ],
        tempId: el.tempId,
        id: el.id,
        currently_working_here: el.currently_working_here ?? false,
        description: el.description,
      };
    },
    removeHistory,
    null,
  );

  const isPresent = Form.useWatch('currently_working_here', form);
  const dates = Form.useWatch('start_end_dates', form);
  const [descriptionInit, setDescriptionInit] = useState([]);

  const description = watcher('description', form);

  useEffect(() => {
    if (isEdit && description) {
      const init = description
        ? isJson(description)
          ? JSON.parse(description)
          : [{type: 'paragraph', data: {text: description}}]
        : [];
      setDescriptionInit(init);
    } else {
      setDescriptionInit([]);
    }
  }, [description, form, isEdit]);

  useEffect(() => {
    if (isPresent) {
      form.setFieldValue('start_end_dates', [dates?.[0], undefined]);
    }
  }, [dates, form, isPresent]);

  const handleSubmitForm = async (values) => {
    const {start_end_dates, description, ...rest} = values;
    const payload = {
      ...rest,
      start_date: start_end_dates?.[0].unix(),
      end_date: start_end_dates?.[1]?.unix() || null,
      description,
    };
    handleSubmit(payload, addHistory, editHistory);
  };

  const getEditorValue = React.useCallback(
    (blocks) => {
      if (blocks?.length) {
        form.setFieldValue('description', JSON.stringify(blocks));
      } else {
        form.setFieldValue('description', undefined);
      }
    },
    [form],
  );

  const handleContinue = () => {
    const values = form.getFieldsValue();
    if (Object.values(values).every((el) => !isUndefined(el))) {
      form.submit();
    }
    onContinue();
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <EmploymentHistory
          isCard={false}
          locale={{emptyText: <></>}}
          history={histories}
          actions={bulkActions}
        />
        {!!histories.length && <Divider />}
        <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
          <Row gutter={[32, 32]} style={{width: '100%'}}>
            <Col xs={24} md={8}>
              <Form.Item
                name='title'
                label='Title'
                rules={[
                  {required: true, message: getRequiredMessage('Title')},
                ]}>
                <AppInput placeholder={getInputPlaceHolder('title')} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name='company'
                label='Company Name'
                rules={[
                  {required: true, message: getRequiredMessage('Company Name')},
                ]}>
                <AppInput placeholder={getInputPlaceHolder('name')} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name='location'
                label='Location'
                rules={[
                  {required: true, message: getRequiredMessage('Location')},
                ]}>
                <AppInput
                  maxLength={100}
                  placeholder={getInputPlaceHolder('location')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space direction='horizontal' size={30} align='end'>
            <Form.Item
              name='start_end_dates'
              label='Start Date / End Date'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('End date'),
                },
              ]}>
              <RangePicker
                disabledDate={disabledDate}
                style={{width: 300}}
                disabled={[false, isPresent]}
                allowEmpty={[false, true]}
                format='MM-DD-YYYY'
                placeholder={['Start date', 'End date']}
              />
            </Form.Item>
            <Form.Item
              name='currently_working_here'
              valuePropName='checked'
              initialValue={false}>
              <Checkbox>I am currently working in this role</Checkbox>
            </Form.Item>
          </Space>

          <Form.Item
            name='description'
            label='Description'
            rules={[
              {required: true, message: getRequiredMessage('Description')},
            ]}>
            <Editor
              isUseAntdform
              key={descriptionInit || 'key'}
              getEditorValue={getEditorValue}
              placeholder={getInputPlaceHolder('description')}
              initValue={descriptionInit}
            />
          </Form.Item>
          <AppFormActions className='d-flex mt-4 mb-4'>
            <Button
              ghost
              type='primary'
              htmlType='submit'
              className='mr-2'
              disabled={!isEnableAddButton}>
              {isEdit ? 'Edit Role' : 'Add Role'}
            </Button>
            {isSomeValue && (
              <Button
                ghost
                className='ml-auto'
                type='primary'
                onClick={handleResetForm}
                htmlType='button'>
                Cancel
              </Button>
            )}
          </AppFormActions>
          <Button type='primary' htmlType='button' onClick={handleContinue}>
            Continue
          </Button>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

EmploymentForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};

export default EmploymentForm;
