import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Modal, Typography} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {RiCloseLine} from 'react-icons/ri';

import ResourceHelper from '../../../../../../utils/ResourceHelper';

const ConfirmCandidateModal = ({isOpenModal, onClose, onSubmit, candidate}) => {
  const [form] = Form.useForm();

  const isAvailable = Form.useWatch('is_available', form);

  return (
    <Modal
      className='submission-reject-modal'
      title='Confirm Candidate'
      open={isOpenModal}
      cancelButtonProps={{type: 'primary', htmlType: 'button', ghost: true}}
      closeIcon={<RiCloseLine fontSize={24} />}
      okText='Confirm'
      width={844}
      onOk={form.submit}
      onCancel={onClose}
      destroyOnClose={true}>
      <Typography.Paragraph>
        If you would like to confirm the availability and billing rate of this
        candidate, select the details below and then click Confirm button. If
        you’d like to reconsider this action, click Cancel.
      </Typography.Paragraph>
      <Form
        onFinish={onSubmit}
        layout='vertical'
        form={form}
        className='modal-reason-dropdown mt-5'>
        <div className='d-flex flex-gap-2'>
          <Form.Item
            rules={[
              {
                required: true,
                message: getRequiredMessage('Candidate Status'),
              },
            ]}
            initialValue={true}
            name='is_available'
            label='Candidate Availability'
            style={{maxWidth: 260, width: '100%'}}>
            <AppSelect
              placeholder={getSelectPlaceHolder('status')}
              size={'middle'}
              options={ResourceHelper.yesNoOptions()}
            />
          </Form.Item>
          <Form.Item
            label='Available On'
            name={'available_on'}
            initialValue={
              !!candidate?.available_on && moment(candidate.available_on)
            }
            rules={[
              {
                required: true,
                message: getRequiredMessage('Available On'),
              },
            ]}
            style={{maxWidth: 260, width: '100%'}}>
            <AppDatePicker style={{width: '100%'}} disabled={!isAvailable} />
          </Form.Item>
          <Form.Item
            style={{maxWidth: 260, width: '100%'}}
            name='billing_rate'
            label='Billing Rate'
            initialValue={candidate?.billing_rate}
            rules={[
              {
                required: true,
                message: getRequiredMessage('Billing rate'),
              },
            ]}>
            <AppInput
              disabled={!isAvailable}
              prefix='$'
              suffix='USD'
              type='number'
              pattern='[0-9]+([\.,][0-9]+)?'
              step='any'
              min={0}
              placeholder={getInputPlaceHolder('billing rate')}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

ConfirmCandidateModal.propTypes = {
  isOpenModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  candidate: PropTypes.object,
};
export default ConfirmCandidateModal;
