import React from 'react';
import {useParams} from 'react-router-dom';

import Integrations from '../Integrations';
import Invitations from '../Invitations';
import Resources from '../Resources';
import Reviews from '../Reviews';
import Users from '../Users';
import Company from './Company';
import Settings from './Settings';

const components = {
  settings: <Settings />,
  users: <Users />,
  reviews: <Reviews />,
  'invite-users': <Invitations />,
  resources: <Resources />,
  integrations: <Integrations />,
};

const MainContent = () => {
  const {category} = useParams();
  return components[category] ?? <Company />;
};

export default React.memo(MainContent);
