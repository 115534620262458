import {Button, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiArrowLeftSLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const BackHeader = ({actions, to}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <Row justify='space-between' align='middle' className='mb-6'>
      <Button
        shape='circle'
        onClick={onClick}
        icon={<RiArrowLeftSLine className='text-xxl text-primary' />}
      />
      {actions}
    </Row>
  );
};

BackHeader.propTypes = {
  actions: PropTypes.node,
  to: PropTypes.string,
};

export default React.memo(BackHeader);
