import ClientHelper from 'app/utils/ClientHelper';

export default {
  status: {
    label: 'Engagement Status',
    options: ClientHelper.getEngagementStatus(),
  },
  engaged_resources: {
    label: '# of Deployed Resources',
    options: ClientHelper.getDeployedResouces(),
    hasQuery: true,
  },
  completed_engagements: {
    label: '# of Completed Engagements',
    options: ClientHelper.completedEngagements(),
    hasQuery: true,
  },
  industry: {
    label: 'Industry',
    options: ClientHelper.getIndustries(),
  },
  relation_status: {
    label: 'Relationship Status',
    options: ClientHelper.getRelationshipStatus(),
  },
  active_suppliers: {
    label: '# of Active Suppliers',
    options: ClientHelper.getActiveSuppliers(),
    hasQuery: true,
  },
  company_size: {
    label: 'Company Size',
    options: ClientHelper.getEmployeeSize(),
  },
  created_at: {
    label: 'Membership Duration',
    options: ClientHelper.getMembershipDuration(),
    hasQuery: true,
  },
  country: {
    label: 'Based In',
    options: ClientHelper.getCountries(),
  },
  open_requisitions: {
    label: '# of Open Jobs',
    options: ClientHelper.getOpenRequisition('open_requisitions'),
    hasQuery: true,
  },
  rating_total: {
    label: 'Performance Rating',
    options: ClientHelper.getPerformanceRating(),
    hasQuery: true,
  },
};
