import {useQueryClient} from 'react-query';

import {integrationAPI} from '../api/integrationApi';
import useReactQuery, {useReactMutation} from '../hooks/useReactQuery';

export const useGetIntegrations = (enabled = true) => {
  return useReactQuery(
    ['integrations'],
    async () => {
      const res = await integrationAPI.getIntegrations();
      return res.data.data;
    },
    {
      enabled,
    },
  );
};
export const useGetIntegration = (id) => {
  return useReactQuery(
    ['integration', id],
    async () => {
      const res = await integrationAPI.getIntegration(id);
      return res.data;
    },
    {
      enabled: !!id,
    },
  );
};

export const useAddIntegration = () => {
  return useReactMutation(async (payload) => {
    const res = await integrationAPI.postIntegration(payload);
    return {
      data: res.data,
      message: 'Integration added successfully',
    };
  });
};

export const useUpdateIntegration = (id) => {
  const queryClient = useQueryClient();
  return useReactMutation(async (payload) => {
    const {onSync, ...data} = payload;
    const res = await integrationAPI.updateIntegration(id, data);
    onSync && integrationAPI.syncIntegration(id).then();
    await queryClient.invalidateQueries(['integration', id]);
    return {
      data: res.data,
      message: 'Integration updated successfully',
    };
  });
};

export const useDeleteIntegration = (id) => {
  return useReactMutation(async () => {
    const data = await integrationAPI.deleteIntegration(id);
    return {
      data,
      message: 'Integration deleted successfully',
    };
  });
};

export const useGetBehalfUser = (id) => {
  return useReactQuery(['behalf'], async () => {
    const res = await integrationAPI.getUsers(id);
    return res.data;
  });
};
export const useGetRejectReasons = (id, isEnabled) => {
  return useReactQuery(
    ['reject_reasons'],
    async () => {
      const res = await integrationAPI.getRejectReasons(id);
      return res.data;
    },
    {
      enabled: !!id && isEnabled,
    },
  );
};
export const useInterviewStages = (id, isEnabled) => {
  return useReactQuery(
    ['interview_stages'],
    async () => {
      const res = await integrationAPI.getInterviewStages(id);
      return res.data;
    },
    {
      enabled: !!id && isEnabled,
    },
  );
};
