import {UserOutlined} from '@ant-design/icons';
import AppScorePopover from '@wieldy/components/AppScore/app-score-popover';
import {Avatar, Card, List, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';

const {Meta} = Card;

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const AppUserProfile = ({
  avatarUrl,
  avatarProps,
  title,
  titleProps,
  items = [],
  renderTitle,
  children,
  className,
  initials,
  isCompany = true,
  useInitialsOnEmptyAvatar = false,
  score,
}) => {
  const showInitials = useInitialsOnEmptyAvatar && !avatarUrl;

  const icon = isCompany ? (
    <RiBuildingLine fontSize={40} />
  ) : (
    <UserOutlined fontSize={30} />
  );

  return (
    <>
      <Meta
        avatar={
          <>
            {showInitials && (
              <Avatar
                size={60}
                style={{
                  ...styles,
                  fontSize: '24px',
                }}>
                {initials}
              </Avatar>
            )}
            {!avatarUrl && !useInitialsOnEmptyAvatar && (
              <Avatar size={60} style={styles} {...avatarProps} icon={icon} />
            )}
            {avatarUrl && (
              <Avatar
                src={avatarUrl}
                size={60}
                style={styles}
                {...avatarProps}
                icon={icon}
              />
            )}
          </>
        }
        title={
          renderTitle ? (
            renderTitle
          ) : (
            <Typography.Link {...titleProps}>{title}</Typography.Link>
          )
        }
        className={`card-meta-root ${className ? className : ''}`}
        description={
          <>
            {items && items.length > 0 && (
              <List
                className='list-item-borderless'
                itemLayout='horizontal'
                dataSource={items}
                renderItem={(item) => (
                  <List.Item className='py-0 hidden'>
                    <List.Item.Meta
                      style={{
                        margin: 0,
                      }}
                      className='align-items-center'
                      avatar={item.icon}
                      title={item.content}
                    />
                  </List.Item>
                )}
              />
            )}
            {children}
          </>
        }
      />
      {score && <AppScorePopover score={score} />}
    </>
  );
};

AppUserProfile.propTypes = {
  avatarUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  avatarProps: PropTypes.object,
  useInitialsOnEmptyAvatar: PropTypes.bool,
  title: PropTypes.node,
  titleProps: PropTypes.object,
  items: PropTypes.array,
  children: PropTypes.node,
  renderTitle: PropTypes.node,
  className: PropTypes.string,
  isCompany: PropTypes.bool,
  score: PropTypes.oneOfType([PropTypes.number, null]),
  initials: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

export default AppUserProfile;
