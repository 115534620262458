import AppTags from '@wieldy/components/AppTags';
import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {BASE_SALARY_PERIOD} from '../../../constants';
import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';

const CompensationCandidateCriteria = ({requisition}) => {
  const {
    isTemporary,
    isContract,
    isC2C,
    base_salary,
    base_salary_min,
    base_salary_max,
    billing_rate,
    billing_rate_min,
    billing_rate_max,
    isDirectHire,
    billingRateMax,
  } = requisition;

  const isBillingRage = isTemporary || (isContract && !isC2C);

  const renderBilling = () => {
    if (isBillingRage) {
      return (
        <Descriptions.Item label={isTemporary ? 'Base Rate' : 'Billing Rate'}>
          {formatBillingRateRage(
            billing_rate,
            billing_rate_min,
            billing_rate_max,
          )}
        </Descriptions.Item>
      );
    }
    if (isDirectHire) {
      return (
        <Descriptions.Item label='Base Salary'>
          {formatBillingRateRage(base_salary, base_salary_min, base_salary_max)}
        </Descriptions.Item>
      );
    }
    return (
      <Descriptions.Item label='Max Bill Rate'>
        {billingRateMax}
      </Descriptions.Item>
    );
  };

  return (
    <div className='mb-5'>
      <Descriptions
        title='Compensation'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-5'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        {renderBilling()}
        {requisition.isDirectHire ? (
          <Descriptions.Item label='Salary Period'>
            {BASE_SALARY_PERIOD[requisition.base_salary_period]}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item
            label={`${isTemporary ? 'Base' : 'Bill'} Rate Period`}>
            {requisition.billingRatePeriod}
          </Descriptions.Item>
        )}

        {requisition.isContract && requisition.isC2C && (
          <Descriptions.Item label='Expenses Eligible'>
            {requisition.expensesEligible}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='FLSA Eligible' span={1}>
          {requisition.flsaEligible}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title='Candidate Criteria'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-5'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Location(s)'>
          <AppTags items={requisition.candidateCountries} />
        </Descriptions.Item>
        <Descriptions.Item label='Visa Status'>
          <AppTags items={requisition.candidateVisaStatus} />
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Experience'>
          {requisition.candidateMinimumExperience}
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Education' span={1}>
          {requisition.candidateMinEducation}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Based In' span={1}>
          <AppTags items={requisition.supplierCountries} />
        </Descriptions.Item>
      </Descriptions>
      {!!requisition?.candidateRequiredChecks?.length && (
        <div className='mb-10'>
          <Typography.Title level={4} className='mb-5'>
            Required Background Checks
          </Typography.Title>

          {requisition.candidateRequiredChecks.map((background) => (
            <Typography.Paragraph key={background}>
              {background}
            </Typography.Paragraph>
          ))}
        </div>
      )}
    </div>
  );
};

CompensationCandidateCriteria.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(CompensationCandidateCriteria);
