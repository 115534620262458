export const primaryTabs = [{key: 'about', title: 'About'}];

export const viewClientQuery = {
  include: [
    'bookmarked',
    'relation_status',
    'engaged_resources',
    'open_requisitions',
    'active_suppliers',
    'total_count',
  ],
  expand: ['company_representative', 'account_manager'],
};

export const TABS = {
  REQUISITIONS: 'jobs',
  ENGAGEMENTS: 'engagements',
  INQUIRIES: 'inquiries',
  SUBMISSIONS: 'submissions',
};

export const getQueryParams = (tab, client) => {
  switch (tab) {
    case TABS.INQUIRIES:
    case TABS.SUBMISSIONS:
      return {
        client,
        expand: ['supplier', 'client', 'requisition'],
        include: ['candidates', 'total_count'],
        sort: ['-created_at'],
      };
    case TABS.ENGAGEMENTS:
      return {
        client,
        expand: ['resource'],
        include: ['total_count'],
        sort: ['-created_at'],
      };
    case TABS.REQUISITIONS:
      return {client, include: ['total_count']};
  }
};

export const getPrimaryTabs = (
  isRequisitions,
  isInquiry,
  isSubmission,
  isEngagement,
  isNotes,
) => {
  const tabs = [...primaryTabs];
  isRequisitions && tabs.push({key: 'requisitions', title: 'Jobs'});
  isInquiry && tabs.push({key: 'inquiries', title: 'Inquiries'});
  isSubmission && tabs.push({key: 'submissions', title: 'Submissions'});
  isEngagement && tabs.push({key: 'engagements', title: 'Engagements'});
  isNotes && tabs.push({key: 'notes', title: 'Notes'});
  return tabs;
};
