import AppScorePopover from '@wieldy/components/AppScore/app-score-popover';
import {Divider, List, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import AppUserProfile from '../../AppUserProfile';

const AppCarouselTpl = ({profileProps, items, score}) => {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <div
          style={{
            width: '100%',
          }}>
          <AppUserProfile avatarProps={{size: 72}} {...profileProps} />
        </div>

        {score && <AppScorePopover score={score} />}
      </div>
      <Divider className='mt-4 mb-3' />
      {items.length > 0 && (
        <List
          className='list-item-borderless'
          itemLayout='horizontal'
          dataSource={items}
          grid={{gutter: 16, column: 2}}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item.icon}
                title={
                  <Typography.Text
                    type='secondary'
                    className='sub-title font-semibold'>
                    {item.title}
                  </Typography.Text>
                }
                description={<Typography.Text>{item.content}</Typography.Text>}
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};

AppCarouselTpl.propTypes = {
  profileProps: PropTypes.object,
  items: PropTypes.array,
  score: PropTypes.oneOfType([PropTypes.object, null]),
};

AppCarouselTpl.defaultProps = {
  items: [],
};

export default AppCarouselTpl;
