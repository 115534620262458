import './index.style.less';

import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {Button, Divider, Empty, Space, Spin, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useSearchFetch} from 'app/hooks/useSearchFetch';
import {query} from 'app/pages/common/Search/data';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {getAddress, getFullName} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import {sliceLastLetter} from 'app/utils/helpers/sliceLastLetter';
import clsx from 'clsx';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useMemo, useState} from 'react';
import {RiSearchLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const pages = {
  resources: {
    getPath: (id) => `resources/${id}/view-resource`,
    getAllPath: (q) => `resources/search/${q}`,
  },
  requisitions: {
    getPath: (id) => `requisitions/${id}/view-requisition`,
    getAllPath: (q) => `requisitions/search/${q}`,
  },
  suppliers: {
    getPath: (id) => `suppliers/${id}/view-supplier`,
    getAllPath: (q) => `suppliers/search/${q}`,
  },
  clients: {
    getPath: (id) => `clients/${id}/view-client`,
    getAllPath: (q) => `clients/search/${q}`,
  },
};

const GlobalSearch = forwardRef(({showSearch, handleToggleSearch}, ref) => {
  const navigate = useNavigate();
  const [string, setString] = useState('');
  const {isClient} = useAccountType();

  const options = useMemo(
    () =>
      isClient
        ? [
            {value: 'suppliers', label: 'Suppliers'},
            {value: 'resources', label: 'Resources'},
          ]
        : [
            {value: 'clients', label: 'Clients'},
            {value: 'requisitions', label: 'Jobs'},
          ],
    [isClient],
  );

  const [entity, setEntity] = useState(options[0]);

  const useFetch = useSearchFetch(entity.value);

  const {data: result, isFetching} = useFetch({
    q: string,
    include: ['total_count'],
    ...query[entity],
  });

  const [data, totalCount] = getItemsData(entity.value, result);

  const debouceRequest = debounce((value) => {
    setString(value);
  }, 1000);

  const onSearch = (value) => {
    debouceRequest(value);
  };

  const onClickViewAll = () => {
    navigate(`${pages[entity.value].getAllPath(string)}`);
    setDropdownOpen(false);
  };

  const handleOpenPage = (id) => {
    const path = pages[entity.value].getPath(id);
    navigate(path);
  };

  const handleSetEntity = (value, option) => {
    setEntity(option);
  };

  const isRequisition = entity.value === 'requisitions';
  const isResource = entity.value === 'resources';

  const getName = useCallback(
    (item) => {
      const d = {
        suppliers: {
          prefix: 'SUP-',
          string: 'Supplier',
        },
        clients: {
          prefix: 'CLI-',
          string: 'Client',
        },
        resources: {
          prefix: 'RES-',
          string: 'Resource',
        },
      };
      const el = d[entity.value];
      return getUserName(
        isResource ? getFullName(item) : item.name,
        el.prefix,
        el.string,
      );
    },
    [entity.value, isResource],
  );

  const resultOptions = useMemo(() => {
    return data.map((el) => ({
      value: el.id,
      label: (
        <div key={el.id}>
          <div className='select-item-title'>
            {sliceLastLetter(entity.label, 's')}
          </div>
          {isRequisition ? (
            <div className='text-primary'>{el.job_title}</div>
          ) : (
            <Space
              className={'flex-wrap'}
              size={0}
              split={<Divider type='vertical' />}>
              <div className='text-primary'>{getName(el)}</div>
              <div>{getAddress(el)}</div>
            </Space>
          )}
        </div>
      ),
    }));
  }, [data, entity.label, getName, isRequisition]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={clsx('header-search', {active: showSearch})} ref={ref}>
      <AppSelect
        style={{
          maxWidth: 300,
          width: '100%',
        }}
        notFoundContent={
          <Empty
            description={isFetching || 'No Results'}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }
        value={'Search'}
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        className={'select-search'}
        loading={isFetching}
        showSearch
        suffixIcon={
          <div onClick={handleToggleSearch}>
            <RiSearchLine fontSize={16} className='text-secondary' />
          </div>
        }
        placeholder={`Search`}
        filterOption={false}
        options={resultOptions}
        onSelect={handleOpenPage}
        popupClassName='select-search'
        dropdownRender={(menu) =>
          string && (
            <div className='search-global'>
              {isFetching ? (
                <div
                  className={'d-flex'}
                  style={{
                    height: 200,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <div className='search-header'>
                    <Typography.Text>
                      {totalCount} results found
                    </Typography.Text>
                  </div>
                  <div>{menu}</div>
                  {!!resultOptions?.length && (
                    <div className='search-footer'>
                      <Button type='link' onClick={onClickViewAll}>
                        View All
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )
        }
        onSearch={onSearch}
      />
      <AppSelect
        value={entity.value}
        onChange={handleSetEntity}
        className='select-categories'
        popupClassName='select-categories'
        size='large'
        options={options}
      />
    </div>
  );
});

GlobalSearch.propTypes = {
  showSearch: PropTypes.bool,
  handleToggleSearch: PropTypes.func,
};

export default GlobalSearch;
