import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Form} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import formConfig from './config';

const FilterClients = ({showRelationStatus = false}) => {
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Engagement Status' name={'status'}>
          <AppSelect
            placeholder={getSelectPlaceHolder('status')}
            options={formConfig.status.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='# of Deployed Resources' name='engaged_resources'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.engaged_resources.options}
            allowClear={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label='# of Completed Engagements'
          name='completed_engagements'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.completed_engagements.options}
            allowClear={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Industry' name='industry'>
          <AppSelect
            placeholder={getSelectPlaceHolder('industry')}
            options={formConfig.industry.options}
            allowClear={true}
            showSearch={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      {showRelationStatus && (
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label='Client Relationship Status' name='relation_status'>
            <AppSelect
              placeholder={getSelectPlaceHolder('status')}
              options={formConfig.relation_status.options}
              allowClear={true}
            />
          </Form.Item>
        </Col>
      )}

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='# of Active Suppliers' name='active_suppliers'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.active_suppliers.options}
            allowClear={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Company Size' name='company_size'>
          <AppSelect
            placeholder={getSelectPlaceHolder('company size(s)')}
            options={formConfig.company_size.options}
            allowClear={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Membership Duration' name='created_at'>
          <AppSelect
            placeholder={getSelectPlaceHolder('duration')}
            options={formConfig.created_at.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Based In' name='country'>
          <AppSelect
            placeholder={getSelectPlaceHolder('countries')}
            options={formConfig.country.options}
            allowClear={true}
            showSearch={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='# of Open Jobs' name='open_requisitions'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.open_requisitions.options}
            allowClear={true}
            mode='multiple'
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Performance Rating' name='rating_total'>
          <AppSelect
            placeholder={getSelectPlaceHolder('rating')}
            options={formConfig.rating_total.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

FilterClients.propTypes = {
  showRelationStatus: PropTypes.bool,
};

export default React.memo(FilterClients);
