import AppUserProfile from '@wieldy/components/AppUserProfile';
import {Rate, Row, Typography} from 'antd';
import {convertHID} from 'app/utils/helpers/ConvertHID.js';
import {getAddress, getFullName} from 'app/utils/helpers/DataFormatHelper.js';
import PropTypes from 'prop-types';
import React from 'react';
import {RiMapPinLine} from 'react-icons/ri';

const CandidateCardHeader = ({candidate, onClick, score}) => {
  const name = candidate?.first_name?.includes('RES')
    ? `Resource ${convertHID(candidate?.first_name, 'RES-')}`
    : getFullName(candidate);
  return (
    <Row justify={'space-between'} wrap={false} className='mb-7'>
      <AppUserProfile
        score={score}
        isCompany={false}
        className='d-flex flex-fill align-items-center view-page'
        avatarUrl={candidate?.avatar || candidate?.resource?.avatar_url}
        useInitialsOnEmptyAvatar={true}
        initials={candidate.initials}
        title={name}
        titleProps={{onClick: onClick}}
        items={[
          {
            icon: <RiMapPinLine fontSize={16} />,
            content: getAddress(candidate),
          },
        ]}>
        <div className='rate-root'>
          <Rate
            className='text-sm'
            disabled
            value={candidate?.rating_total || 0}
            count={5}
          />
          <Typography.Text className='font-semibold text-xs ml-2'>
            ({candidate?.rating_count || 0})
          </Typography.Text>
        </div>
      </AppUserProfile>
    </Row>
  );
};

CandidateCardHeader.propTypes = {
  candidate: PropTypes.object,
  onClick: PropTypes.func,
  score: PropTypes.oneOfType([PropTypes.number, null]),
};

export default CandidateCardHeader;
