export default {
  ALL: 'all',
  AVAILABLE: 'available',
  AVAILABLE_SOON: 'available_soon',
  UNAVAILABLE: 'unavailable',
  PENDING: 'pending',
  POTENTIAL: 'potential',
  FORMER: 'former',
  FILLED: 'filled',
  ACTIVE: 'active',
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  IMPORTED: 'imported',
  CLOSED: 'closed',
  OPEN: 'open',
  SUBMITTED: 'submitted',
  BROWSE: 'browse',
  BOOKMARKED: 'bookmarked',
  COMPLETED: 'completed',
  GUARANTEE_PERIOD: 'guarantee_period',
};
