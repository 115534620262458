import AppCard from '@wieldy/components/AppCard';
import {Button, Dropdown, List, Menu, Space, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {formatUnixDate} from 'app/utils/helpers/DataFormatHelper';
import {isJson} from 'app/utils/helpers/isJson';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {RiMore2Fill} from 'react-icons/ri';

import {formatDate} from '../../../utils/helpers/AppHelper';
import Editor from '../../components/Editor/Editor';

const EmploymentHistory = ({
  history,
  isCard = true,
  actions = null,
  ...rest
}) => {
  const renderContent = () => (
    <List
      {...rest}
      dataSource={history}
      renderItem={(item) => {
        return (
          <>
            <List.Item
              key={item.id}
              style={{display: 'flex', alignItems: 'flex-start'}}>
              <div>
                <Typography.Title level={4}>
                  {`${item.title} | ${item.company}`}
                </Typography.Title>
                <Space direction='vertical'>
                  <Typography.Text>{item.location}</Typography.Text>
                  <Typography.Text type='secondary'>
                    {isJson(item.description) ? (
                      <Editor
                        key={item.description}
                        initValue={JSON.parse(item.description)}
                        readOnly={true}
                      />
                    ) : (
                      item.description
                    )}
                  </Typography.Text>
                </Space>
              </div>
              <Space
                style={{
                  flexShrink: 0,
                  width: 200,
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  right: 0,
                }}>
                <Typography.Text>
                  {`${formatDate(Number(item.start_date), DATE_FORMATS.sm)} - ${
                    item.end_date
                      ? formatDate(Number(item.end_date), DATE_FORMATS.sm)
                      : 'Present'
                  }`}
                </Typography.Text>
                {actions && (
                  <Dropdown
                    overlay={
                      <Menu>
                        {actions({
                          ...item,
                          start_end_dates: [
                            item.start_date &&
                              moment(formatUnixDate(Number(item.start_date))),
                            item.end_date &&
                              moment(formatUnixDate(Number(item.end_date))),
                          ],
                        }).map(({title, ...restProps}) => (
                          <Menu.Item key={title} {...restProps}>
                            {title}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement='bottomRight'
                    trigger='click'>
                    <Button
                      type='text'
                      shape='circle'
                      size='small'
                      icon={<RiMore2Fill className={'text-xl'} />}
                    />
                  </Dropdown>
                )}
              </Space>
            </List.Item>
          </>
        );
      }}
    />
  );

  if (isCard) {
    return (
      <AppCard
        title='Employment History'
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 8}}>
        {renderContent()}
      </AppCard>
    );
  }

  return (
    <>
      {/*<Typography.Title level={4}>Employment History</Typography.Title>*/}
      {renderContent()}
    </>
  );
};

EmploymentHistory.propTypes = {
  history: PropTypes.object,
  isCard: PropTypes.bool,
  actions: PropTypes.func,
};

export default EmploymentHistory;
