import AppGridContainer from '@wieldy/components/AppGridContainer';
import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Col} from 'antd';
import {
  useGetActiveEngagementsReport,
  useGetActiveSuppliersReport,
  useGetOpenRequisitionsReport,
  useGetTotalEarningsReport,
} from 'app/services/clientService';
import AreaChartWidget from 'app/shared/charts/AreaChartWidget';
import {
  chartFromDate,
  getOptions,
  mergeActiveEngagementsToData,
  mergeActiveSuppliersChartToData,
  mergeChartToData,
  mergeMoneyChartToData,
} from 'app/shared/forms/FilterReportDropdown';
import {useCurrentUser} from 'app/store/features/user/userSlice';
import {
  formatAmount,
  formatSecondsToDate,
} from 'app/utils/helpers/DataFormatHelper';
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import {CartesianGrid, XAxis, YAxis} from 'recharts';

const MainContentClient = () => {
  const {width} = useWindowSize();
  const user = useCurrentUser();
  const options = getOptions(moment.unix(user?.created_at).format('MM/DD/YY'));
  // Open Requisitions
  const [openRequisitionsDate, setOpenRequisitionsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: openRequisitionsLoading, data: openRequisitions} =
    useGetOpenRequisitionsReport({
      from_date: openRequisitionsDate,
    });
  const chartOpenRequisitions = chartFromDate(openRequisitionsDate);
  if (openRequisitions) {
    mergeChartToData(
      chartOpenRequisitions,
      openRequisitions.items.map((item) => ({
        start_date: formatSecondsToDate(item.created_at, 'MM/DD/YY'),
        end_date: formatSecondsToDate(item.closed_at, 'MM/DD/YY'),
      })),
    );
  }
  // Active Suppliers
  const [activeSuppliersDate, setActiveSuppliersDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: activeSuppliersLoading, data: activeSuppliers} =
    useGetActiveSuppliersReport({
      from_date: activeSuppliersDate,
    });
  const chartActiveSuppliers = chartFromDate(activeSuppliersDate);
  if (activeSuppliers) {
    mergeActiveSuppliersChartToData(
      chartActiveSuppliers,
      activeSuppliers.items,
    );
  }
  // Active Engagements
  const [activeEngagementsDate, setActiveEngagementsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: activeEngagementsLoading, data: activeEngagements} =
    useGetActiveEngagementsReport({
      from_date: activeEngagementsDate,
    });
  const chartActiveEngagements = chartFromDate(activeEngagementsDate);
  if (activeEngagements) {
    mergeActiveEngagementsToData(
      chartActiveEngagements,
      activeEngagements.items.map((item) => ({
        start_date: item.status_active_date,
        end_date: item.status_active_end_date,
      })),
    );
  }
  // Total Spends
  let totalEarned = 0;
  const [totalEarningsDate, setTotalEarningsDate] = useState(
    options[options.findIndex(({key}) => key === 'last_30')].date,
  );
  const {isLoading: totalEarningsLoading, data: totalEarnings} =
    useGetTotalEarningsReport({
      from_date: totalEarningsDate,
    });

  const chartTotalEarnings = chartFromDate(totalEarningsDate);
  if (totalEarnings) {
    totalEarned = formatAmount(
      totalEarnings.items.reduce(
        (acc, item) => acc + item.client_total_billable,
        0,
      ),
    );
    mergeMoneyChartToData(
      chartTotalEarnings,
      totalEarnings.items,
      'client_total_billable',
    );
  }

  const graphHeight = useMemo(() => {
    let height = 220;
    if (width >= 1600) {
      height = 228;
    } else if (width >= 992) {
      height = 284;
    }
    return height;
  }, [width]);

  return (
    <AppGridContainer>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Total Spend'
              dataSource={chartTotalEarnings}
              description={`${totalEarned} ${options
                .find(({date}) => date === totalEarningsDate)
                .label.toLowerCase()}`}
              loading={totalEarningsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setTotalEarningsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }
              isDollars>
              <CartesianGrid vertical={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                axisLine={false}
                tickFormatter={(i) => `$${i}`}
              />
            </AreaChartWidget>
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Open Jobs'
              dataSource={chartOpenRequisitions}
              loading={openRequisitionsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setOpenRequisitionsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
      <Col xs={24}>
        <AppGridContainer>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Active Engagements'
              dataSource={chartActiveEngagements}
              loading={activeEngagementsLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setActiveEngagementsDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
          <Col xs={24} lg={12}>
            <AreaChartWidget
              title='Active Suppliers'
              dataSource={chartActiveSuppliers}
              loading={activeSuppliersLoading}
              height={graphHeight}
              onChange={(dateKey) =>
                setActiveSuppliersDate(
                  options.find(({key}) => key === dateKey).date,
                )
              }>
              <CartesianGrid vertical={false} />
              <YAxis allowDecimals={false} tickLine={false} axisLine={false} />
              <XAxis dataKey={'dateLabel'} minTickGap={16} tickSize={8} />
            </AreaChartWidget>
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

export default React.memo(MainContentClient);
