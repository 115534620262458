import {Divider, Typography} from 'antd';
import cn from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const RequisitionCardDetail = ({requisition, hideMarginBottom = false}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/requisitions/${requisition?.id}/view-requisition`);
  };
  return (
    <>
      <div className={hideMarginBottom ? '' : 'mb-6'}>
        <Typography.Title type={'secondary'} level={5} className='title'>
          Job <span className='title-divider' style={{maxWidth: 60}} />
        </Typography.Title>
        <Typography.Title level={4} className={'mb-1'}>
          <Typography.Link onClick={handleClick}>
            {requisition?.jobTitle}
          </Typography.Link>
        </Typography.Title>
        <Typography.Paragraph
          className={cn('text-sm', hideMarginBottom && 'mb-0')}>
          <Typography.Text type={'secondary'}>
            #{requisition?.hid}
          </Typography.Text>
          <Divider type='vertical' />
          <Typography.Text type={'secondary'}>
            Created on {requisition?.created}
          </Typography.Text>
        </Typography.Paragraph>
      </div>
    </>
  );
};

RequisitionCardDetail.propTypes = {
  requisition: PropTypes.object,
  hideMarginBottom: PropTypes.bool,
};

export default RequisitionCardDetail;
