import {useMutation} from 'react-query';

import {candidateAPI} from '../api/candidateApi';
import {requisitionAPI} from '../api/requisitionApi';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from '../hooks/useReactQuery';

const useUpdateCandidate = (key = 'inquiry', candidateId) => {
  const client = useQueryClient();
  return useReactMutation(async ({id, candidate}) => {
    const {data} = await candidateAPI.updateCandidate(id, candidate);
    await client.invalidateQueries([key, candidateId]);
    return {
      message: 'Candidate updated successfully',
      data,
    };
  });
};

const useDeleteCandidate = (key = 'inquiry', tableId) => {
  const client = useQueryClient();
  return useReactMutation(async (id) => {
    const {data} = await candidateAPI.deleteCandidate(id);
    await client.invalidateQueries([key, tableId]);
    return {
      message: 'Candidate deleted successfully',
      data,
    };
  });
};

const useExportCandidate = () => {
  return useReactMutation(async (id) => {
    const {data} = await candidateAPI.exportCandidate(id);
    return {
      message: 'Candidate exported successfully',
      data,
    };
  });
};
const useGetCandidates = (queryParams = {}, options = {}) => {
  return useReactQuery(
    ['candidates', queryParams],
    async () => {
      const {data} = await candidateAPI.getCandidates(queryParams);
      return {
        candidates: data?.data || [],
        total_count: data.total_count,
      };
    },
    options,
  );
};

const useGetAllCandidates = (params = {}, statuses = []) => {
  return useReactQuery(
    ['candidates', params],
    async () => {
      const allPromise = statuses.map((status) =>
        candidateAPI.getCandidates({...params, status}),
      );
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        candidates: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

const useGetCandidateDetails = (
  id,
  queryParams = {},
  requisitionQueryParams = {},
) => {
  return useReactQuery(['candidate', id], async () => {
    const {data} = await candidateAPI.getCandidate(id, queryParams);
    if (data.requisition) {
      const {data: requisition} = await requisitionAPI.getRequisition(
        data.requisition.id,
        requisitionQueryParams,
      );

      return {
        candidate: data,
        requisition,
      };
    }

    return {
      candidate: data,
      requisition: {},
    };
  });
};

const useDownloadCandidate = () => {
  return useMutation({
    mutationFn: async ({id}) => {
      const data = await candidateAPI.getResourcePdf({
        id,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      return data;
    },
  });
};

export {
  useDeleteCandidate,
  useDownloadCandidate,
  useExportCandidate,
  useGetAllCandidates,
  useGetCandidateDetails,
  useGetCandidates,
  useUpdateCandidate,
};
