import {DATE_FORMATS} from 'app/config/app';
import RequisitionHelper from 'app/utils/RequisitionHelper';

import {
  BACKGROUND_CHECKS,
  BILLING_RATE_PERIODS,
  COUNTRIES,
  EDUCATIONS,
  JOB_PRIORITIES,
  JOB_STATUSES,
  REQUISITION_STATUS,
  TRAVEL_REQUIREMENTS,
  VISA_STATUSES,
  WORKSITES,
} from '../constants';
import {
  CONTRACT_TYPES,
  NEW_WORK_TYPES,
  WORK_TYPE_KEYS,
} from '../constants/work-types';
import BaseModal from './BaseModal';

class RequisitionModal extends BaseModal {
  constructor(requisition = {}, supplier = null) {
    super(requisition);
    this.id = requisition?.id ?? '';
    this.integration_job_id = requisition?.integration_job_id || null;
    this.hid = requisition?.hid ?? 'N/A';
    this.job_function = requisition?.job_function || null;
    this.jobTitle = requisition?.job_title ?? '';
    this.openings = requisition?.openings ?? null;
    this.filled = requisition?.filled_openings ?? 0;
    this.jobStatus = requisition?.job_status
      ? JOB_STATUSES[requisition?.job_status]
      : null;
    this.jobPriority = requisition?.priority
      ? JOB_PRIORITIES[requisition?.priority]
      : null;
    this.jobOverview = requisition?.job_overview ?? null;
    this.jobRoleDescription = requisition?.job_role_description ?? null;
    this.jobResponsibilities = requisition?.job_responsibilities ?? null;
    this.jobSkillsExperience = requisition?.job_skills_experience ?? null;
    this.jobWorkSite = requisition?.job_worksite
      ? WORKSITES[requisition?.job_worksite]
      : null;
    this.jobCountry = this.getCountry(requisition?.job_country);
    this.jobLocation = this.getLocation(requisition);
    this.jobDuration =
      requisition?.job_duration !== null
        ? this.getJobDuration(requisition?.job_duration)
        : null;
    this.travelRequirements = requisition?.travel_requirements
      ? TRAVEL_REQUIREMENTS[requisition?.travel_requirements]
      : null;
    this.billing_rate = requisition?.billing_rate;
    this.billing_rate_max = requisition?.billing_rate_max;
    this.billing_rate_min = requisition?.billing_rate_min;

    this.billingRateMax = requisition?.billing_rate_max
      ? this.formatAmountView(requisition?.billing_rate_max)
      : null;
    this.billingRatePeriod = requisition?.billing_rate_period
      ? BILLING_RATE_PERIODS[requisition?.billing_rate_period]
      : 'Hourly';
    this.expensesEligible =
      requisition?.expenses_eligible !== null
        ? requisition?.expenses_eligible === true
          ? 'Yes'
          : 'No'
        : null;
    this.flsaEligible =
      requisition?.flsa_eligible !== null
        ? requisition?.flsa_eligible === true
          ? 'Exempt'
          : 'Non Exempt'
        : null;
    this.candidateCountries = this.getCountry(requisition?.candidate_country);
    this.candidateVisaStatus = this.getCandidateVisaStatus(
      requisition?.candidate_visa_status,
    );
    this.supplierCountries = this.getCountry(requisition?.supplier_country);
    this.candidateMinimumExperience = this.getCandidateExperience(
      requisition?.candidate_min_experience,
    );
    this.candidateMinEducation = this.getEducation(
      requisition?.candidate_min_education,
    );
    this.candidateRequiredChecks = requisition?.candidate_required_checks
      ? this.getRequiredChecks(requisition?.candidate_required_checks)
      : [];
    this.type = requisition?.type;
    this.workType = requisition?.type
      ? NEW_WORK_TYPES[requisition?.type]
      : null;
    this.contract_type = requisition?.contract_type;
    this.contractType = CONTRACT_TYPES[this.contract_type] || null;
    this.status = requisition?.status ? requisition?.status : null;
    this.statusName = this.status && REQUISITION_STATUS[requisition?.status];

    this.start_date = requisition?.start_date ?? null;
    this.end_date = requisition?.end_date ?? null;
    this.startDate =
      this.start_date && this.formatDate(this.start_date, DATE_FORMATS.md);
    this.endDate =
      this.end_date && this.formatDate(this.end_date, DATE_FORMATS.md);
    this.startDateTbl =
      this.start_date && this.formatDate(this.start_date, '/');
    this.endDateTbl = this.end_date && this.formatDate(this.end_date, '/');

    this.skillTags = requisition?.skill_tags
      ? this.getSkillTags(requisition?.skill_tags)
      : [];
    this.hiringManager = requisition?.hiring_manager
      ? this.getUser(requisition?.hiring_manager)
      : null;
    this.recruiter = requisition?.recruiter
      ? this.getUser(requisition?.recruiter)
      : null;
    this.bookmarked = requisition?.bookmarked ?? false;
    this.isPreviewMode = false;
    this.changeStatusLabelVisibility();

    // Create or update timestamp
    this.createTimestamp = requisition?.created_at ?? null;
    this.updateTimestamp = requisition?.updated_at ?? null;
    this.createdAt =
      this.createTimestamp &&
      this.formatDate(this.createTimestamp, DATE_FORMATS.lg);
    this.updatedAt =
      this.updateTimestamp &&
      this.formatDate(this.updateTimestamp, DATE_FORMATS.lg);
    this.created =
      this.createTimestamp &&
      this.formatDate(this.createTimestamp, DATE_FORMATS.md); // use on card
    this.updated =
      this.updateTimestamp && this.formatRelativeTime(this.updateTimestamp); // use on card

    this.inquiries =
      requisition?.inquiries ||
      requisition?.client_inquiries ||
      requisition?.common_inquiries ||
      requisition?.requisition_common_inquiries ||
      0;
    this.submissions =
      requisition?.submissions ||
      requisition?.client_submissions ||
      requisition?.common_submissions ||
      requisition?.requisition_common_submissions ||
      0;
    this.engagements =
      requisition?.engagements ||
      requisition?.client_engagements ||
      requisition?.common_engagements ||
      requisition?.requisition_common_engagements ||
      0;
    this.placements =
      requisition?.placements ||
      requisition?.client_placements ||
      requisition?.common_placements ||
      requisition?.requisition_common_placements ||
      0;
    this.client = this.buildExpandClient(requisition, true);
    this.supplier = supplier;
    this.insuranceRequired = requisition.client?.insurance_required;
    this.accept_candidates_from = requisition?.accept_candidates_from || null;
    this.base_salary = requisition?.base_salary || null;
    this.base_salary_min = requisition?.base_salary_min || null;
    this.base_salary_max = requisition?.base_salary_max || null;
    this.base_salary_period = requisition?.base_salary_period || 'annually';
    this.recruitment_fee = requisition?.recruitment_fee || null;
    this.recruitment_fee_structure =
      requisition?.recruitment_fee_structure || 'percentage_of_salary';
    this.guarantee_period = requisition?.guarantee_period || '60_days';
    this.guarantee_period_action =
      requisition?.guarantee_period_action || 'candidate_replacement';
    this.isOnlyDirectSourcing = requisition?.accept_candidates_from
      ? this.getIsOnlyDirectSourcing(requisition?.accept_candidates_from)
      : false;
    this.isDirectHire = requisition?.type
      ? this.getIsDirectHire(requisition.type)
      : false;
    this.isC2C = this.contract_type === 'c2c';
    this.isContract = this.type === WORK_TYPE_KEYS.contract;
    this.isTemporary = this.type === WORK_TYPE_KEYS.temporary;

    this.part_time = requisition?.part_time;
    this.partTime = this.part_time ? 'Yes' : 'No';
    this.integration = requisition?.integration;
  }

  changeStatusLabelVisibility() {
    this.statusColor = '#D7FFD7';
    this.statusClass = 'success';
    this.tagColor = 'success';
    if (this.status === 'closed' || this.status === 'filled') {
      this.statusColor = '#D2D2D2';
      this.statusClass = 'secondary';
      this.tagColor = 'default';
    }
  }

  getJobDuration(duration) {
    const durationItem = RequisitionHelper.durations().find(
      (item) => item.value === duration,
    );
    return durationItem && durationItem.label;
  }

  getCountry = (value) => {
    if (!value || (Array.isArray(value) && !value.length)) {
      return ['Any country'];
    }

    if (Array.isArray(value)) {
      return value.map((country) => COUNTRIES[country]);
    }

    return [COUNTRIES[value]];
  };

  getCandidateVisaStatus(items) {
    if (!items || (Array.isArray(items) && !items.length)) {
      return ['No visa requirements'];
    }

    if (Array.isArray(items) && items.length) {
      return items.map((itemkey) => VISA_STATUSES[itemkey]);
    }
  }

  getCandidateExperience(experienceTime) {
    if (!experienceTime) {
      return 'Any experience';
    }
    const item = RequisitionHelper.candidateMinExperience().find(
      (item) => item.value === experienceTime,
    );
    return item ? item.label : 'Any experience';
  }

  getEducation(value) {
    if (!value) {
      return 'Any education';
    }
    return EDUCATIONS[value] || 'Any education';
  }
  getRequiredChecks(requiredChecks) {
    return Array.isArray(requiredChecks)
      ? requiredChecks.map((itemKey) => BACKGROUND_CHECKS[itemKey])
      : [];
  }

  getSkillTags(skillTags) {
    let tags = skillTags;
    if (typeof skillTags === 'object' && skillTags?.data) {
      tags = skillTags?.data.map((item) => {
        const tag = item.tag;
        tag.rstId = item?.id;
        return tag;
      });
    }
    return Array.isArray(tags) ? tags : [];
  }

  getIsOnlyDirectSourcing(acceptCandidatesFrom) {
    return acceptCandidatesFrom.every((item) => item === 'direct_sourcing');
  }

  getIsDirectHire(type) {
    return [WORK_TYPE_KEYS.part_time, WORK_TYPE_KEYS.full_time].includes(type);
  }
}

export default RequisitionModal;
