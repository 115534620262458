import {Descriptions} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import {useStatus} from 'app/hooks/useStatus';
import {
  formatAmount,
  formatPayUnits,
  formatSecondsToDate,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const Overview = ({timecard}) => {
  const {
    hid,
    status,
    engagement,
    supplier,
    requisition,
    pay_units,
    net_total,
    created_by,
    created_at,
    submitted_by,
    submitted_at,
    approved_by,
    approved_at,
    rejected_by,
    rejected_at,
    rejected_reason,
    resource,
    client,
    invoice,
    invoice_author,
  } = timecard;

  const {isSupplier} = useAccountType();
  const {isResource} = useRole();
  const {isApproved, isRejected, isNew} = useStatus(status);

  return (
    <div className='mb-6'>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Resource ID'>
          {isResource ? (
            resource?.hid || ''
          ) : (
            <Link to={`/resources/${resource?.id}/view-resource`}>
              {resource?.hid || ''}
            </Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Engagement ID'>
          {isResource ? (
            engagement?.hid || ''
          ) : (
            <Link to={`/engagements/${engagement.id}/view-engagement`}>
              {engagement?.hid || ''}
            </Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={isSupplier ? 'Client ID' : 'Supplier ID'}>
          {isSupplier ? (
            isResource ? (
              client?.hid || ''
            ) : (
              <Link to={`/clients/${client.id}/view-client`}>
                {client?.hid || ''}
              </Link>
            )
          ) : (
            <Link to={`/suppliers/${supplier.id}/view-supplier`}>
              {supplier?.hid || ''}
            </Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Job Title'>
          {requisition?.job_title || ''}
        </Descriptions.Item>
        <Descriptions.Item label='Pay Units'>
          {formatPayUnits(pay_units)}
        </Descriptions.Item>
        {isResource || (
          <Descriptions.Item label='Net Total'>
            {formatAmount(net_total || 0)}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Created By'>
          {getFullName(created_by)}
        </Descriptions.Item>
        <Descriptions.Item label='Created'>
          {formatSecondsToDate(created_at)}
        </Descriptions.Item>
        {isNew || (
          <>
            <Descriptions.Item label='Submitted By'>
              {getFullName(submitted_by)}
            </Descriptions.Item>
            <Descriptions.Item label='Submitted'>
              {formatSecondsToDate(submitted_at)}
            </Descriptions.Item>
          </>
        )}
        {isApproved && (
          <>
            <Descriptions.Item label='Approved By'>
              {getFullName(approved_by)}
            </Descriptions.Item>
            <Descriptions.Item label='Approved'>
              {formatSecondsToDate(approved_at)}
            </Descriptions.Item>
            {invoice && (
              <>
                <Descriptions.Item label='Invoice Number'>
                  {invoice?.hid || ''}
                </Descriptions.Item>
                <Descriptions.Item label='Invoiced By'>
                  {getFullName(invoice_author)}
                </Descriptions.Item>
                <Descriptions.Item label='Invoiced'>
                  {formatSecondsToDate(invoice?.created_at)}
                </Descriptions.Item>
              </>
            )}
          </>
        )}
        {isRejected && (
          <>
            <Descriptions.Item label='Rejected By'>
              {getFullName(rejected_by)}
            </Descriptions.Item>
            <Descriptions.Item label='Rejected'>
              {formatSecondsToDate(rejected_at)}
            </Descriptions.Item>
            <Descriptions.Item label='Reject Reason'>
              {rejected_reason}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label='Timecard ID' span={1}>
          {hid}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

Overview.propTypes = {
  timecard: PropTypes.object,
};

export default Overview;
