import {Button, Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const SecondStep = ({isActive, isConnected, handleDisconnect}) => {
  const navigate = useNavigate();
  return (
    <>
      <Typography.Title level={4}>
        Step 2: Create Custom Field in Greenhouse
      </Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        If you prefer to automatically import all open jobs from Greenhouse into
        your Sourcer account, you can skip this section. If you prefer for your
        recruiters to select which Greenhouse jobs should be imported into your
        Sourcer account, follow the instructions below to implement a custom
        field to allow Greenhouse users to specify whether a job should be sent
        to Sourcer. You will also need to select the &apos;Import selected jobs
        from Greenhouse&apos; import option on Settings page after the initial
        connection has been established
      </Typography.Paragraph>
      <Typography.Title level={5}>Create Custom Field</Typography.Title>
      <ol style={{paddingLeft: 14, marginBottom: 30}}>
        <li>
          Click the <b>Configure</b> icon on your Greenhouse application&apos;s
          navigation bar.
        </li>
        <li>
          On the left navigation menu, click on <b>Custom Options</b> section
          and select <b>Jobs</b> link under <b>Company custom fields</b>{' '}
          section.
        </li>
        <li>
          Click <b>Add field</b>
        </li>
        <li>
          Enter &apos;Send to Sourcer&apos; as <b>Field name</b>, type a{' '}
          <b>Description</b> to be shown to Greenhouse users, select
          &apos;Yes/No&apos; as <b>Field type</b>, then click <b>Save</b>.
        </li>
      </ol>
      {!isConnected && (
        <>
          <Typography.Title level={5}>Establish Connection</Typography.Title>
          <Typography.Paragraph style={{marginBottom: 20}}>
            Once the API key has been entered and the custom field created (if
            needed), click <b>Connect</b> below to establish the connection
            between your Greenhouse and Sourcer accounts.
          </Typography.Paragraph>
        </>
      )}
      {!isConnected &&
        (isActive ? (
          <Button style={{marginBottom: 40}} onClick={handleDisconnect}>
            Disconnect
          </Button>
        ) : (
          <Space style={{marginBottom: 40}}>
            <Button type='primary' htmlType='submit'>
              Connect
            </Button>
            <Button onClick={() => navigate('/administration/integrations')}>
              Cancel
            </Button>
          </Space>
        ))}
    </>
  );
};

SecondStep.propTypes = {
  isActive: PropTypes.bool,
  isConnected: PropTypes.bool,
  handleDisconnect: PropTypes.func,
};
export default SecondStep;
