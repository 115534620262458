import AppCard from '@wieldy/components/AppCard';
import {Form} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';

import {
  useAddIntegration,
  useUpdateIntegration,
} from '../../../../../../../services/integrationSevice';
import FirstStep from './first-step';
import SecondStep from './second-step';
import ThirdStep from './third-step';

const GreenHouseSetup = ({isConnected, data}) => {
  const queryClient = useQueryClient();
  const addIntegration = useAddIntegration();
  const updateIntegration = useUpdateIntegration(data?.id);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = ({api_key}) => {
    if (isConnected) {
      updateIntegration.mutate(
        {
          isActive: true,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(['integration']);
          },
        },
      );
    } else {
      const payload = {
        name: 'greenhouse',
        integration: {
          apiKey: api_key,
        },
      };
      addIntegration.mutate(payload, {
        onSuccess: async (res) => {
          navigate(
            `/administration/integrations/greenhouse/${res.data.id}/setup`,
          );
        },
      });
    }
  };

  const handleDisconnect = () => {
    updateIntegration.mutate(
      {
        isActive: false,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['integration']);
        },
      },
    );
  };

  return (
    <AppCard title='Set Up Greenhouse Integration'>
      <Form form={form} onFinish={onFinish}>
        <FirstStep data={data} />
        <SecondStep
          isConnected={isConnected}
          isActive={data?.isActive}
          handleDisconnect={handleDisconnect}
        />
      </Form>
      {isConnected && <ThirdStep data={data} />}
    </AppCard>
  );
};

GreenHouseSetup.propTypes = {
  isConnected: PropTypes.bool,
  data: PropTypes.object,
};

export default GreenHouseSetup;
