import Label from '@register-app/components/additional/label';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import {message} from 'antd';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {MdContentCopy} from 'react-icons/md';

const CopyInput = ({label, defValue, placeholder, type}) => {
  const [value, setValue] = useState(defValue || '');
  return (
    <div className='mb-5'>
      <Label text={label} />
      <div className='d-flex align-items-center flex-gap-2'>
        <AppInput
          type={type}
          placeholder={placeholder}
          readOnly
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <MdContentCopy
          size={30}
          style={{
            cursor: 'pointer',
          }}
          onClick={async () => {
            await navigator.clipboard.writeText(value);
            message.success(`Copied`);
          }}
        />
      </div>
    </div>
  );
};

CopyInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defValue: PropTypes.string,
  type: PropTypes.string,
};
export default CopyInput;
