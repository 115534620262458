import AppScoreCard from '@wieldy/components/AppScore/app-score';
import {Popover, Progress, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const AppScorePopover = ({score}) => {
  return (
    <Popover placement='right' content={<AppScoreCard score={score} />}>
      <Progress
        width={40}
        type='circle'
        percent={score?.overall_score}
        format={(percent) => (
          <Typography.Text
            style={{fontSize: 10}}>{`${percent}%`}</Typography.Text>
        )}
      />
    </Popover>
  );
};

AppScorePopover.propTypes = {
  score: PropTypes.object,
};
export default AppScorePopover;
