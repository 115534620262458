import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from 'react-router-dom';

import GreenHouseSettings from './settings/settings';
import GreenHouseSetup from './setup';

const MainContent = ({isConnected, data}) => {
  const {tab} = useParams();

  if (tab === 'settings') {
    return <GreenHouseSettings data={data} />;
  }
  return <GreenHouseSetup isConnected={isConnected} data={data} />;
};

MainContent.propTypes = {
  isConnected: PropTypes.bool,
  data: PropTypes.object,
};
export default MainContent;
