import {
  AddEditClientReview,
  AddEditResourceReview,
  AddEditSupplierReview,
  CreateResource,
  default as Administration,
  InviteNewUser,
  ViewUser,
} from 'app/pages/main/administration';
import {
  Admin,
  InviteNewManager,
  ViewMarketplaceUser,
} from 'app/pages/manager/administartion';

import GreenHouse from '../../pages/main/administration/Integrations/greenhouse';

export const supplier_AdministrationRoutes = [
  {
    path: '/administration',
    element: <Administration />,
  },
  {
    path: '/administration/:category',
    element: <Administration />,
  },
  {
    path: '/administration/users/:id/view-user',
    element: <ViewUser />,
  },
  {
    path: '/administration/users/invite-user',
    element: <InviteNewUser />,
  },
  {
    path: '/administration/resource/add-resource',
    element: <CreateResource />,
  },
  {
    path: 'administration/client/:id/review',
    element: <AddEditClientReview />,
  },
];

export const client_AdministrationRoutes = [
  {
    path: 'administration',
    element: <Administration />,
  },
  {
    path: 'administration/:category',
    element: <Administration />,
  },
  {
    path: 'administration/integrations/greenhouse/:id/:tab',
    element: <GreenHouse />,
  },
  {
    path: 'administration/integrations/greenhouse/:tab',
    element: <GreenHouse />,
  },
  {
    path: '/administration/users/invite-user',
    element: <InviteNewUser />,
  },
  {
    path: 'administration/users/:id/view-user',
    element: <ViewUser />,
  },
  {
    path: 'administration/supplier/:id/review',
    element: <AddEditSupplierReview />,
  },
  {
    path: 'administration/resource/:id/review',
    element: <AddEditResourceReview />,
  },
];

export const manager_admin = [
  {
    path: 'administration',
    element: <Admin />,
  },
  {
    path: 'administration/:category',
    element: <Admin />,
  },
  {
    path: '/administration/users/invite-user',
    element: <InviteNewManager />,
  },
  {
    path: 'administration/users/:id/view-user',
    element: <ViewMarketplaceUser />,
  },
];
