import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import {useReduceInfinityResponse} from 'app/hooks/useReduceInfinityResponse';
import ResourceModal from 'app/modals/ResourceModal';
import {useGetInfinityResource} from 'app/services/resourceService';
import CreateList from 'app/shared/components/inquiry/CreateList';
import FilterResources from 'app/shared/resource/FilterResources';
import {setSubmissionCandidates} from 'app/store/features/submission/inquirySlice';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {APPLICANT_TYPE_KEYS} from '../../../../../../../constants';
import {
  CONSTRACT_OPTIONS_KEYS,
  WORK_TYPE_KEYS,
} from '../../../../../../../constants/work-types';
import {useAccountType} from '../../../../../../../hooks/useAccountType';
import ApplicantModal from '../../../../../../../modals/ApplicantModal';
import {useGetApplicants} from '../../../../../../../services/applicantService';
import {useGetSupplierRequisitionInvitation} from '../../../../../../../services/requisitionInvitationService';
import CandidateDetails from '../CandidateDetails';

const ChooseSupplierContainer = ({onContinue}) => {
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const supplierId = useSelector(({user}) => user.currentAccount.supplier.id);
  const {isSupplier} = useAccountType();

  const {isEditMode, existingCandidates, requisition} = useSelector(
    ({submission}) => submission,
  );

  const isIncludeTalentVendor = requisition?.accept_candidates_from?.includes(
    CONSTRACT_OPTIONS_KEYS.talent_vendors,
  );

  const isDirectHire = useMemo(
    () =>
      requisition.type === WORK_TYPE_KEYS.full_time ||
      requisition.type === WORK_TYPE_KEYS.part_time,
    [requisition],
  );

  const getRequisitionInvitation = useGetSupplierRequisitionInvitation(
    requisition.id,
    {enabled: !!requisition.id && isSupplier},
  );

  const isNotAcceptedInvite = useMemo(() => {
    return (
      getRequisitionInvitation.data?.requisitionInvitation?.[0]?.accepted ===
      null
    );
  }, [getRequisitionInvitation.data]);

  const isNotSentInvite = useMemo(() => {
    return !getRequisitionInvitation.data?.requisitionInvitation?.length;
  }, [getRequisitionInvitation.data]);

  const isDeclinedInvite = useMemo(() => {
    return (
      getRequisitionInvitation.data?.requisitionInvitation?.[0]?.accepted ===
      false
    );
  }, [getRequisitionInvitation.data]);

  const currentFormattedCandidate = useMemo(
    () =>
      currentCandidate && currentCandidate.type
        ? new ApplicantModal(currentCandidate)
        : new ResourceModal(currentCandidate),
    [currentCandidate],
  );

  const handleCurrentCandidate = useCallback((can) => {
    setCurrentCandidate(can);
  }, []);

  const {filterQuery, queryInit} = usePageQuery();

  const {data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage} =
    useGetInfinityResource(
      {
        ...queryInit,
        target_requisition: requisition?.id,
        expand: [...queryInit.expand, 'supplier', 'user'],
        ...filterQuery,
        supplier: supplierId,
        view_all: true,
      },
      {enabled: !isDirectHire && !!requisition.id && isIncludeTalentVendor},
    );

  const applicantTypeParam = useMemo(() => {
    if (isDirectHire) {
      return APPLICANT_TYPE_KEYS.permanent;
    }

    return APPLICANT_TYPE_KEYS.temporary;
  }, [isDirectHire]);

  const {isLoading: isLoadingApplicants, data: applicantsData} =
    useGetApplicants(
      {
        ...queryInit,
        target_requisition: requisition?.id,
        expand: [...queryInit.expand, 'supplier'],
        ...filterQuery,
        supplier: supplierId,
        view_all: true,
        type: applicantTypeParam,
      },
      {enabled: !getRequisitionInvitation.isLoading},
    );

  const [candidates, totalCount] = useReduceInfinityResponse(data);
  const mappedCandidates = useMemo(() => {
    const getIsDisabled = (type) => {
      if (
        (isNotSentInvite || isNotAcceptedInvite || isDeclinedInvite) &&
        !isDirectHire &&
        type === APPLICANT_TYPE_KEYS.temporary
      ) {
        return true;
      }
      if (
        isNotAcceptedInvite &&
        isDirectHire &&
        type === APPLICANT_TYPE_KEYS.permanent
      ) {
        return true;
      }
      return false;
    };

    const applicants =
      applicantsData?.applicants.map((el) => ({
        ...el,
        isDisable: getIsDisabled(el.type),
      })) || [];

    if (isEditMode) {
      const candidatesWithExist = candidates.map((el) => ({
        ...el,
        isExist: existingCandidates.includes(el.id),
      }));

      const applicantsWithExist = applicants.map((el) => ({
        ...el,
        isExist: existingCandidates.includes(el.id),
        isDisable: getIsDisabled(el.type),
      }));

      return [...candidatesWithExist, ...applicantsWithExist];
    }
    return [...candidates, ...applicants];
  }, [
    applicantsData?.applicants,
    isEditMode,
    candidates,
    isNotSentInvite,
    isNotAcceptedInvite,
    isDeclinedInvite,
    isDirectHire,
    existingCandidates,
  ]);

  useEffect(() => {
    if (mappedCandidates.length && !currentCandidate) {
      handleCurrentCandidate(mappedCandidates[0]);
    }
    if (!mappedCandidates.length) {
      handleCurrentCandidate(null);
    }
  }, [currentCandidate, handleCurrentCandidate, mappedCandidates]);

  return (
    <>
      <AppGridContainer>
        <CreateList
          isSubmission={true}
          isLoading={isLoading || isLoadingApplicants}
          title='Candidates'
          items={mappedCandidates}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          totalCount={totalCount + applicantsData?.total_count}
          filter={
            <FilterResources
              additionalFilters={{type: true}}
              req={requisition}
            />
          }
          actionCreator={setSubmissionCandidates}
          handleCurrentItem={handleCurrentCandidate}
          currentItem={currentCandidate}
          isCalcItems={true}
          onContinue={onContinue}
        />
        {currentCandidate && (
          <Col xs={24} lg={16}>
            <AppCard style={{height: 'fit-content', minHeight: 300}}>
              <CandidateDetails candidate={currentFormattedCandidate} />
            </AppCard>
          </Col>
        )}
      </AppGridContainer>
    </>
  );
};

ChooseSupplierContainer.propTypes = {
  onContinue: PropTypes.func,
};

export default ChooseSupplierContainer;
