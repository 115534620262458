import AppCard from '@wieldy/components/AppCard';
import AppScoreCard from '@wieldy/components/AppScore/app-score';
import {Col, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Score = ({score}) => {
  return (
    <AppCard
      title='Candidate Score'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <AppScoreCard score={score} />
        </Col>
        <Col span={12}>
          <Typography.Title level={4} style={{marginBottom: 6}}>
            Summary
          </Typography.Title>
          <Typography.Text>{score.summary}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Title level={4} style={{marginBottom: 6}}>
            Matching Skills
          </Typography.Title>
          <ul style={{paddingLeft: 12}}>
            {!!score.matching_skills &&
              score.matching_skills.map((el, index) => (
                <li key={'matching' + index}>
                  <Typography.Text>{el}</Typography.Text>
                </li>
              ))}
          </ul>
        </Col>
        <Col span={12}>
          <Typography.Title level={4} style={{marginBottom: 6}}>
            Missing Skills
          </Typography.Title>
          <ul style={{paddingLeft: 12}}>
            {!!score.missing_skills &&
              score.missing_skills.map((el, index) => (
                <li key={'matching' + index}>
                  <Typography.Text>{el}</Typography.Text>
                </li>
              ))}
          </ul>
        </Col>
      </Row>
    </AppCard>
  );
};

Score.propTypes = {
  score: PropTypes.oneOfType([PropTypes.object, null]),
};
export default Score;
