import {Button, Dropdown, Menu, Tooltip, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {
  formatBillingRateRage,
  formatDate,
} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {RiMore2Fill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {PERIODS} from '../../../../../../constants';
import {
  CANDIDATE_STATUSES,
  CANDIDATE_STATUSES_LABEL,
} from '../../../../../../constants/candidate-statuses';

export const useGetColumns = ({
  isShowActions,
  handleOpenWithdrawCandidate,
  handleChangeCandidateStatus,
  requisition,
}) => {
  const navigate = useNavigate();
  const {isClient} = useAccountType();

  const handleSinglePageView = (id) => {
    navigate(`/candidates/${id}/view-candidate`);
  };

  const actions = {
    title: '',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 64,
    render: (_, {status_key, id}) => {
      return (
        <Dropdown
          overlay={
            <Menu>
              {isClient ? (
                <Menu.Item
                  key='status'
                  title='Change Status / Stage'
                  onClick={() => handleChangeCandidateStatus(id)}>
                  Change Status / Stage
                </Menu.Item>
              ) : (
                <Menu.Item
                  key='withdraw'
                  title='Withdraw Candidate'
                  onClick={() => handleOpenWithdrawCandidate(id)}>
                  Withdraw Candidate
                </Menu.Item>
              )}
            </Menu>
          }
          placement='bottomRight'
          disabled={
            [CANDIDATE_STATUSES.HIRED, CANDIDATE_STATUSES.ENGAGED].includes(
              status_key,
            ) ||
            (status_key === CANDIDATE_STATUSES.REJECTED && !isClient)
          }
          trigger='hover'>
          <Button
            type='text'
            shape='circle'
            size='small'
            icon={<RiMore2Fill className={'text-xl'} />}
          />
        </Dropdown>
      );
    },
  };

  const base = [
    {
      title: 'Name / Location',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <React.Fragment>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              <Typography.Link onClick={() => handleSinglePageView(item.id)}>
                {item.name}
              </Typography.Link>
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </React.Fragment>
        );
      },
    },
    {
      title: requisition.isDirectHire ? 'Salary' : 'Rate',
      dataIndex: 'billingRate',
      key: 'billing_rate',
      editable: true,
      render: (rate, row) => {
        const {
          base_salary,
          base_salary_min,
          base_salary_max,
          billing_rate,
          billing_rate_min,
          billing_rate_max,
        } = row;

        const salary = formatBillingRateRage(
          base_salary,
          base_salary_min,
          base_salary_max,
          false,
        );

        const billing = formatBillingRateRage(
          billing_rate,
          billing_rate_min,
          billing_rate_max,
          false,
          true,
        );

        if (requisition.isDirectHire) {
          return (
            <Typography.Text>
              {`${salary} ${salary !== 'N/A' ? '/ year' : ''}`}
            </Typography.Text>
          );
        }

        return (
          <Typography.Text>
            {billing +
              (billing.includes('N/A')
                ? ''
                : row?.applicant?.billing_rate_period
                ? `/ ${PERIODS[row?.billing_rate_period] || 'hour'}`
                : '/ hour')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Available on',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (value) => {
        return (
          <Typography.Text style={{width: 120, display: 'inline-block'}}>
            {formatDate(value, DATE_FORMATS.md) || 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Submitted On',
      dataIndex: 'inquired_on',
      key: 'inquired_on',
      render: (value) => (
        <Typography.Text style={{width: 120, display: 'inline-block'}}>
          {formatDate(value, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
    {
      title: 'Status / Stage',
      dataIndex: 'status_key',
      key: 'status_key',
      render: (
        status,
        {interviewStageLabel, rejectReasonLabel, status_key},
      ) => {
        const isRejected = [
          'rejected',
          'engaged',
          'converted',
          'hired',
        ].includes(status_key);

        return (
          <div className='d-flex flex-column align-items-start'>
            <Typography.Text className={'text-sm mb-0'}>
              {CANDIDATE_STATUSES_LABEL[status_key] ?? status}
              {isRejected && (
                <Tooltip title={rejectReasonLabel} className='ml-2'>
                  <AiOutlineInfoCircle style={{height: 16, width: 16}} />
                </Tooltip>
              )}
            </Typography.Text>
            {isRejected ||
              (interviewStageLabel && (
                <Typography.Text>({interviewStageLabel})</Typography.Text>
              ))}
          </div>
        );
      },
    },
  ];

  return isShowActions ? [...base, actions] : base;
};
