import axios from '../config/axios';

export const integrationAPI = {
  getIntegrations: async () => await axios.get('/custom_client_integrations'),
  postIntegration: async (data) =>
    await axios.post('/custom_client_integrations', data),
  syncIntegration: async (id) =>
    await axios.post(`/custom_client_integrations/${id}/sync`),
  getIntegration: async (id) =>
    await axios.get(`/custom_client_integrations/${id}`),
  updateIntegration: async (id, data) =>
    await axios.patch(`/custom_client_integrations/${id}`, data),
  deleteIntegration: async (id) =>
    await axios.delete(`/custom_client_integrations/${id}`),
  getUsers: async (id) => {
    return await axios.get(
      `/custom_client_integrations/${id}/integration_users`,
    );
  },
  getRejectReasons: async (id) => {
    return await axios.get(
      `/custom_client_integrations/${id}/integration_reject_reasons`,
    );
  },
  getInterviewStages: async (id) => {
    return await axios.get(
      `/custom_client_integrations/${id}/integration_requisition_interview_stages`,
    );
  },
};
