import {Tooltip, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {CANDIDATE_STATUSES_LABEL} from 'app/constants/candidate-statuses.js';
import {SUBMISSION_STATUSES} from 'app/constants/submission-statuses.js';
import CandidateModal from 'app/modals/CandidateModal';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import {
  formatDate,
  getAddress,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';

export const getColumns = (
  isClient,
  isHideSupplier,
  isDirectHire,
  isHideBilling,
) => {
  const supplier = {
    title: 'Supplier',
    dataIndex: 'role',
    key: 'role',
    render: (_, {supplier, created_from_job_board}) => {
      if (created_from_job_board) {
        return <Typography.Text>{supplier?.name}</Typography.Text>;
      }
      return (
        <Link
          to={`/suppliers/${supplier?.id}/view-supplier`}
          component={Typography.Link}>
          {supplier?.name}
        </Link>
      );
    },
  };

  const billingRate = {
    title: isDirectHire ? 'Base Salary' : 'Billing Rate',
    dataIndex: 'billingRateLabel',
    key: 'billingRateLabel',
    width: 200,
    render: (_, {candidates}) => {
      const candidate = new CandidateModal(candidates?.[0]);
      if (isDirectHire) {
        const salary = candidate?.baseSalaryLabel;
        return (
          <Typography.Text>
            {salary} {salary !== 'N/A' ? '/ year' : ''}
          </Typography.Text>
        );
      }
      return <Typography.Text>{candidate.billingRateLabel}</Typography.Text>;
    },
  };

  const start = [
    {
      title: 'Submission',
      dataIndex: 'title',
      key: 'title',
      render: (_, {id, hid, requisition}) => (
        <Typography.Paragraph>
          <Typography.Paragraph className={'mb-0'}>
            <Link
              to={`/submissions/${id}/view-submission`}
              component={Typography.Link}>
              {requisition?.job_title}
            </Link>
          </Typography.Paragraph>
          <Typography.Text type={'secondary'} className={'text-xs'}>
            {hid}
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Submission Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Typography.Text>
          {SUBMISSION_STATUSES[value] || capitalizeFirstLetter(value)}
        </Typography.Text>
      ),
    },
    {
      title: 'Submitted On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => (
        <Typography.Text>
          {formatDate(created_at, DATE_FORMATS.md)}
        </Typography.Text>
      ),
    },
  ];

  const defaultValues = [
    {
      title: 'Candidate Name / Location',
      dataIndex: 'candidate',
      key: 'candidate',
      width: 240,
      render: (_, {candidates}) => {
        const candidate = candidates?.[0];
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${
                  candidate?.id || candidate?.candidate_id
                }/view-candidate`}
                component={Typography.Link}>
                {getFullName(candidate?.resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(candidate?.resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      width: 200,
      render: (_, {candidates}) => {
        const cand = candidates?.[0];
        return (
          <Typography.Text>
            {formatDate(cand?.available_on, DATE_FORMATS.md) || 'N/A'}
          </Typography.Text>
        );
      },
    },
  ];

  const status = {
    title: 'Candidate Status',
    dataIndex: 'candidate_status',
    key: 'candidate_status',
    width: 200,
    render: (_, {candidates}) => {
      const cand = candidates?.[0];
      const isRejected = cand?.status_key === 'rejected';
      return (
        <div className='d-flex flex-column align-items-start'>
          <Typography.Text className={'text-sm mb-0'}>
            {CANDIDATE_STATUSES_LABEL[cand?.status_key] ?? cand?.status_key}
            {isRejected && (
              <Tooltip title={cand?.rejectReasonLabel} className='ml-2'>
                <AiOutlineInfoCircle style={{height: 16, width: 16}} />
              </Tooltip>
            )}
          </Typography.Text>
          {isRejected ||
            (cand?.interviewStageLabel && (
              <Typography.Text>({cand?.interviewStageLabel})</Typography.Text>
            ))}
        </div>
      );
    },
  };

  const showSupplier = isClient && !isHideSupplier;

  return [
    ...start,
    ...(showSupplier ? [supplier] : []),
    ...defaultValues,
    ...(isHideBilling ? [] : [billingRate]),
    status,
  ];
};

export const getExpandedCols = ({
  isDirectHire,
  isHideBilling,
  isShowSupplier,
}) => {
  const billing = {
    title: 'Billing Rate',
    dataIndex: 'billingRateLabel',
    key: 'billingRateLabel',
    width: 200,
    render: (_, cand) => {
      const candidate = new CandidateModal(cand);
      if (isDirectHire) {
        const salary = candidate?.baseSalaryLabel;
        return (
          <Typography.Text>
            {salary} {salary !== 'N/A' ? '/ year' : ''}
          </Typography.Text>
        );
      }
      return <Typography.Text>{candidate.billingRateLabel}</Typography.Text>;
    },
  };
  const emptyColumns = Array(isShowSupplier ? 4 : 3).fill({});

  const defaultValues = [
    {
      title: 'Candidate Name / Location',
      dataIndex: 'candidate',
      key: 'candidate',
      width: 240,
      render: (_, candidate) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${
                  candidate?.id || candidate?.candidate_id
                }/view-candidate`}
                component={Typography.Link}>
                {getFullName(candidate?.resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(candidate?.resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      width: 200,
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
  ];

  const status = {
    title: 'Candidate Status',
    dataIndex: 'status_key',
    key: 'status_key',
    width: 200,
    render: (
      value,
      {candidate_status, rejectReasonLabel, interviewStageLabel},
    ) => {
      const isRejected = value === 'rejected';

      return (
        <div className='d-flex flex-column align-items-start'>
          <Typography.Text className={'text-sm mb-0'}>
            {CANDIDATE_STATUSES_LABEL[candidate_status] ?? candidate_status}
            {isRejected && (
              <Tooltip title={rejectReasonLabel} className='ml-2'>
                <AiOutlineInfoCircle style={{height: 16, width: 16}} />
              </Tooltip>
            )}
          </Typography.Text>
          {isRejected ||
            (interviewStageLabel && (
              <Typography.Text>({interviewStageLabel})</Typography.Text>
            ))}
        </div>
      );
    },
  };

  return [
    ...emptyColumns,
    ...defaultValues,
    ...(isHideBilling ? [] : [billing]),
    status,
  ];
};
