import AppScorePopover from '@wieldy/components/AppScore/app-score-popover';
import {Tooltip, Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {CANDIDATE_STATUSES_LABEL} from 'app/constants/candidate-statuses.js';
import {INQUIRY_STATUSES} from 'app/constants/inquiry-statuses.js';
import {SUBMISSION_STATUSES} from 'app/constants/submission-statuses.js';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {
  formatDate,
  getAddress,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import {insert} from 'app/utils/helpers/Insert.js';
import React from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {Link} from 'react-router-dom';

export const getColumns = (isClient, isDirectHire) => {
  const supplier = {
    title: 'Supplier',
    dataIndex: 'role',
    key: 'role',
    render: (_, {supplier, created_from_job_board}) => {
      if (created_from_job_board) {
        return (
          <Typography.Text>
            {getUserName(supplier?.name, 'SUP-', 'Supplier')}
          </Typography.Text>
        );
      }
      return (
        <Link
          to={`/suppliers/${supplier?.id}/view-supplier`}
          component={Typography.Link}>
          {getUserName(supplier?.name, 'SUP-', 'Supplier')}
        </Link>
      );
    },
  };
  const defaultArray = [
    {
      title: 'Name / Location',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource, id}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${id}/view-candidate`}
                component={Typography.Link}>
                {getFullName(resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      sorter: true,
      showSorterTooltip: false,
      render: (score) => {
        if (!score) {
          return 'N/A';
        }
        return <AppScorePopover score={score} />;
      },
    },
    {
      title: isDirectHire ? 'Base Salary' : 'Billing Rate',
      dataIndex: 'billingRateLabel',
      key: 'billingRateLabel',
      width: 100,
      render: (billingRateLabel, candidate) => {
        if (isDirectHire) {
          const salary = candidate?.baseSalaryLabel;
          return (
            <Typography.Text>
              {' '}
              {salary} {salary !== 'N/A' ? '/ year' : ''}
            </Typography.Text>
          );
        }
        return <Typography.Text>{billingRateLabel}</Typography.Text>;
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
    {
      title: 'Candidate Status',
      dataIndex: 'status_key',
      key: 'status_key',
      render: (
        value,
        {candidate_status, rejectReasonLabel, interviewStageLabel},
      ) => {
        const isRejected = value === 'rejected';

        return (
          <div className='d-flex flex-column'>
            <Typography.Text className={'text-sm mb-0'}>
              {CANDIDATE_STATUSES_LABEL[value] ?? candidate_status}
              {isRejected && (
                <Tooltip title={rejectReasonLabel} className='ml-2'>
                  <AiOutlineInfoCircle style={{height: 16, width: 16}} />
                </Tooltip>
              )}
            </Typography.Text>
            {isRejected ||
              (interviewStageLabel && (
                <Typography.Text>({interviewStageLabel})</Typography.Text>
              ))}
          </div>
        );
      },
    },
    {
      title: 'Inquiry / Submission',
      dataIndex: 'title',
      key: 'title',
      render: (_, {inquiry, submission, requisition}) => {
        const type = inquiry || submission;
        const path = inquiry
          ? `/inquiries/${type?.id}/view-inquiry`
          : `/submissions/${type?.id}/view-submission`;

        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link to={path} component={Typography.Link}>
                {requisition?.jobTitle}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {type?.hid}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Inquiry / Submission Status',
      dataIndex: 'inquiry/submission_status',
      key: 'inquiry/submission_status',
      render: (_, {inquiry, submission}) => {
        let status;
        if (inquiry) {
          status =
            INQUIRY_STATUSES[inquiry.status] ||
            capitalizeFirstLetter(inquiry.status);
        }

        if (submission) {
          status =
            SUBMISSION_STATUSES[submission.status] ||
            capitalizeFirstLetter(submission.status);
        }

        return (
          <Typography.Text>{capitalizeFirstLetter(status)}</Typography.Text>
        );
      },
    },
  ];

  return isClient
    ? insert(defaultArray, 4, supplier)
    : defaultArray.filter((el) => el.key !== 'score');
};
