import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import {options, sort} from 'app/config/chat';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {
  getChannelFilterByType,
  groupChannelsByFields,
} from 'app/utils/helpers/ChatHelpers';
import React, {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import AppChat from '../AppChat';

const tabsData = [
  {
    key: 'accounts',
    title: 'Accounts',
  },
  {
    key: 'requisitions',
    title: 'Jobs',
  },
];

const MarketplaceChat = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const channelId = searchParams.get(MESSAGE_QUERIES.channelId);

  const isRequisitionChannel = useMemo(() => {
    if (channelId) {
      return channelId.includes('req');
    }

    return false;
  }, [channelId]);

  const [activeTab, setActiveTab] = useState(
    isRequisitionChannel ? tabsData[1] : tabsData[0],
  );

  const channelListConfig = useMemo(() => {
    const filtersChannel = getChannelFilterByType(
      activeTab.key === 'accounts' ? 'marketplace' : 'requisition',
    );

    return {
      options,
      sort,
      filters: filtersChannel,
    };
  }, [activeTab]);

  const renderChannels = (loadedChannels, getChannelPreview) => {
    const group = groupChannelsByFields(loadedChannels, ['supplier', 'client']);
    const channels =
      activeTab.key === 'accounts'
        ? loadedChannels.filter((channel) => channel.data.marketplace_channel)
        : group;

    return channels.map((channel) => getChannelPreview(channel));
  };

  return (
    <>
      <AppDynamicTabs
        tabsData={tabsData}
        activeKey={activeTab.key}
        setActiveTab={setActiveTab}
      />
      <AppChat
        channelListConfig={channelListConfig}
        renderChannels={renderChannels}
      />
    </>
  );
};

export default MarketplaceChat;
