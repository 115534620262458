import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {useCurrentUser} from '../../../../../../../store/features/user/userSlice';
import CopyInput from '../../common/copy-input';

const ThirdStep = () => {
  const {integrations_secret, id: clientId} = useCurrentUser();
  const webUrl = `${process.env.REACT_APP_BASE_URL}/webhooks/${clientId}`;

  return (
    <>
      <Typography.Title level={4}>
        Step 3: Set Up Webhooks in Greenhouse
      </Typography.Title>
      <Typography.Paragraph style={{marginBottom: 20}}>
        Follow the instructions below to set up the required webhooks in
        Greenhouse in order for your integration to function properly and enable
        our advanced customizations
      </Typography.Paragraph>
      <Typography.Title level={5}>Set Up Webhooks</Typography.Title>
      <ol style={{paddingLeft: 14}}>
        <li>
          Click the <b>Configure</b> icon on your Greenhouse application&apos;s
          navigation bar.
        </li>
        <li>
          On the left navigation menu, click on <b>Dev Center</b> section and
          select <b>API Credential Management</b> link.
        </li>
        <li>
          On the left navigation menu, click on <b>Webhooks</b> section and
          select <b>Webhooks</b> link.
        </li>
        <li>
          <p>
            Use the data below to create each webhook. You need to create 12
            different webhooks that are triggered on Greenhouse events with the
            same names as below:
          </p>
          <p className='mb-0'>* Job created</p>
          <p className='mb-0'>* Job updated</p>
          <p className='mb-0'>* Job deleted</p>
          <p className='mb-0'>* Job post created</p>
          <p className='mb-0'>* Candidate or prospect rejected</p>
          <p className='mb-0'>* Candidate or prospect unrejected</p>
          <p className='mb-0'>* Candidate has changed stage</p>
          <p className='mb-0'>* Candidate has been hired</p>
          <p className='mb-0'>* Candidate has been unhired</p>
          <p className='mb-0'>* Delete candidate</p>
          <p className='mb-0'>* Delete application</p>
          <p className='mb-5'>* Offer created</p>
        </li>
        <li>
          Type a descriptive name for each webhook, select when it should be
          triggered, and then copy <b>Endpoint URL, Secret key</b> and{' '}
          <b>Error recipient email</b> values from the fields below.
        </li>
        <li>
          When finished, click <b>Create Webhook</b> and proceed to the next
          one.
        </li>
      </ol>
      <div style={{maxWidth: 500}}>
        <CopyInput
          label='Endpoint URL'
          placeholder='Enter Endpoint URl'
          defValue={webUrl}
        />
        <CopyInput
          label='Secret Key'
          placeholder='Enter Secret Key'
          defValue={integrations_secret}
          type='password'
        />
        <CopyInput
          label='Error Recipient Email'
          placeholder='Enter Error Recipient Email'
          defValue='support+int+greenhouse@sourcer.com'
        />
      </div>

      <p>
        After all webhooks have been configured in Greenhouse, proceed to{' '}
        <b>Settings</b> tab above to select your preferred integration settings
        for your Greenhouse and Sourcer accounts
      </p>
    </>
  );
};

ThirdStep.propTypes = {
  data: PropTypes.object,
};
export default ThirdStep;
