import axios from '../config/axios';

export const candidateAPI = {
  getCandidates: async (params) => {
    const expand = [...params.expand, 'inquiry', 'submission', 'supplier'];
    return await axios.get('/candidates', {params: {...params, expand}});
  },
  getCandidate: async (id, params) => {
    const expand = [...params.expand, 'inquiry', 'submission', 'supplier'];
    return await axios.get(`/candidates/${id}`, {params: {...params, expand}});
  },
  deleteCandidate: async (id) => {
    return await axios.delete(`/candidates/${id}`);
  },
  getResourcePdf: async ({id, options}) =>
    await axios.get(`/candidates/${id}/download`, {
      ...options,
    }),
  updateCandidate: async (id, data) => {
    return await axios.patch(`/candidates/${id}`, data);
  },
  exportCandidate: async (id_candidate) => {
    return await axios.post(`candidates/${id_candidate}/export_to_integration`);
  },
};
