import AppCard from '@wieldy/components/AppCard';
import {AppFormActions, AppFormBody} from '@wieldy/components/AppFormWrapper';
import AppFormWrapper from '@wieldy/components/AppFormWrapper/AppFormWrapper';
import AppLoader from '@wieldy/components/AppLoader';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Radio, Row} from 'antd';
import {useForm} from 'antd/es/form/Form';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {BASE_SALARY_PERIOD} from '../../../../../../constants';
import {setBilling} from '../../../../../../store/features/resource/resourceSlice';
import {getOptionsFromObject} from '../../../../../../utils/helpers/GetOptionsFromObject';
import {allowPositiveExactNumber} from '../../../../../../utils/helpers/preventNegativeNumber';
import ResourceHelper from '../../../../../../utils/ResourceHelper';

const radioOptions = (isPermanent) => {
  return [
    {label: isPermanent ? 'Base salary' : 'Billing Rate', value: 'exact'},
    {
      label: isPermanent ? 'Base Salary Range' : 'Billing Rate Range',
      value: 'range',
    },
  ];
};

const BillingAndEnvironmentForm = ({onContinue}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const {billing, isInit, summary} = useSelector(({resource}) => resource);
  const [billingType, setBillingType] = useState('exact');

  const handleSubmit = (values) => {
    const salaryFields = values.base_salary
      ? {
          base_salary: values.base_salary,
          base_salary_min: null,
          base_salary_max: null,
        }
      : {
          base_salary: null,
          base_salary_min: values.base_salary_min,
          base_salary_max: values.base_salary_max,
        };

    const billingFields = values.billing_rate
      ? {
          billing_rate: values.billing_rate,
          billing_rate_min: null,
          billing_rate_max: null,
        }
      : {
          billing_rate: null,
          billing_rate_min: values.billing_rate_min,
          billing_rate_max: values.billing_rate_max,
        };
    dispatch(setBilling({...values, ...salaryFields, ...billingFields}));
    onContinue();
  };

  useEffect(() => {
    if (
      summary.isPermanent
        ? billing.base_salary_min || billing.base_salary_max
        : billing.billing_rate_min || billing.billing_rate_max
    ) {
      setBillingType('range');
    } else {
      setBillingType('exact');
    }
  }, [billing, summary]);

  const handleChangeBillingType = useCallback(
    (e) => {
      setBillingType(e.target.value);
      if (e.target.value === 'range') {
        form.setFieldValue(
          summary.isPermanent ? 'base_salary' : 'billing_rate',
          undefined,
        );
      } else {
        form.setFieldValue(
          summary.isPermanent ? 'base_salary_min' : 'billing_rate_min',
          undefined,
        );
        form.setFieldValue(
          summary.isPermanent ? 'base_salary_max' : 'billing_rate_max',
          undefined,
        );
      }
    },
    [form, summary.isPermanent],
  );

  const isRange = billingType === 'range';

  const validate = (_, value, callback) => {
    const numberRegex = /^\d*(.\d{0,2})?$/;
    if (value && !numberRegex.test(value)) {
      callback('Billing Rate is not a valid');
    } else {
      callback();
    }
  };

  if (!isInit) {
    return <AppLoader />;
  }

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
          initialValues={billing}>
          <AppFormBody>
            <Radio.Group
              options={radioOptions(summary.isPermanent)}
              onChange={handleChangeBillingType}
              value={billingType}
            />
            <Row align='bottom'>
              {isRange || (
                <Col className='mr-2'>
                  <Form.Item
                    name={summary.isPermanent ? 'base_salary' : 'billing_rate'}
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage(
                          summary.isPermanent ? 'Salary' : 'Rate',
                        ),
                      },
                      {validator: validate},
                    ]}>
                    <AppInputNumber
                      placeholder={`Enter exact ${
                        summary.isPermanent ? 'salary' : 'rate'
                      }`}
                      prefix='$'
                      addonAfter='USD'
                      type='number'
                      pattern='[0-9]+([\.,][0-9]+)?'
                      step='any'
                      style={{width: '220px'}}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              )}
              {isRange && (
                <>
                  <Col className='mr-2'>
                    <Form.Item
                      name={
                        summary.isPermanent
                          ? 'base_salary_min'
                          : 'billing_rate_min'
                      }
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage(
                            summary.isPermanent ? 'Salary' : 'Rate',
                          ),
                        },
                      ]}>
                      <AppInputNumber
                        onKeyDown={allowPositiveExactNumber}
                        placeholder={
                          summary.isPermanent ? 'From salary' : 'From rate'
                        }
                        prefix='$'
                        addonAfter='USD'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        style={{width: '200px'}}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col className='mr-2'>
                    <Form.Item
                      name={
                        summary.isPermanent
                          ? 'base_salary_max'
                          : 'billing_rate_max'
                      }
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage(
                            summary.isPermanent ? 'Salary' : 'Rate',
                          ),
                        },
                      ]}>
                      <AppInputNumber
                        onKeyDown={allowPositiveExactNumber}
                        placeholder={
                          summary.isPermanent ? 'To salary' : 'To rate'
                        }
                        prefix='$'
                        addonAfter='USD'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        style={{width: '200px'}}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <div style={{maxWidth: 280, width: '100%'}}>
              {summary.isPermanent ? (
                <Form.Item
                  name='base_salary_period'
                  label='Salary Period'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Salary Period'),
                    },
                  ]}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('period')}
                    options={getOptionsFromObject(BASE_SALARY_PERIOD)}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name='billing_rate_period'
                  label='Billing Rate Period'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Billing Rate Period'),
                    },
                  ]}>
                  <AppSelect
                    placeholder={getSelectPlaceHolder('period')}
                    options={ResourceHelper.billingRatePeriods()}
                  />
                </Form.Item>
              )}
              <Form.Item
                name='billing_rate_negotiations'
                label={
                  summary.isPermanent
                    ? 'Open to Salary Negotiation'
                    : 'Open to Rate Negotiation'
                }
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage(
                      summary.isPermanent
                        ? 'Open to Salary Negotiation'
                        : 'Open to Rate Negotiation',
                    ),
                  },
                ]}>
                <AppSelect
                  placeholder='Is open to negotiation'
                  options={ResourceHelper.yesNoOptions()}
                />
              </Form.Item>
              {!summary.isPermanent && (
                <Form.Item
                  name='billing_rate_buy_out'
                  label='Open to Resource Buyout'
                  rules={[
                    {
                      required: false,
                      message: getRequiredMessage('Open to Resource Buyout'),
                    },
                  ]}>
                  <AppSelect
                    placeholder='Is open to buyout'
                    options={ResourceHelper.yesNoOptions()}
                  />
                </Form.Item>
              )}
            </div>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

BillingAndEnvironmentForm.propTypes = {
  onContinue: PropTypes.func,
  onSave: PropTypes.func,
};

export default BillingAndEnvironmentForm;
