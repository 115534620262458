import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {BASE_SALARY_PERIOD} from '../../../../constants';
import {formatBillingRateRage} from '../../../../utils/helpers/DataFormatHelper';

const InformationView = ({requisitionModal}) => {
  const {
    billing_rate,
    billing_rate_min,
    billing_rate_max,
    base_salary,
    base_salary_min,
    base_salary_max,
    isTemporary,
    isContract,
    isC2C,
  } = requisitionModal;

  const isBillingRage = isTemporary || (isContract && !isC2C);
  const name = isTemporary ? 'Base' : 'Billing';
  const renderBilling = () => {
    if (isBillingRage) {
      return (
        <Descriptions.Item label={`${name} Rate`}>
          {formatBillingRateRage(
            billing_rate,
            billing_rate_min,
            billing_rate_max,
          )}
        </Descriptions.Item>
      );
    }
    if (requisitionModal.isDirectHire) {
      return (
        <Descriptions.Item label='Base Salary'>
          {formatBillingRateRage(base_salary, base_salary_min, base_salary_max)}
        </Descriptions.Item>
      );
    }
    return (
      <Descriptions.Item label='Max Bill Rate'>
        {requisitionModal.billingRateMax}
      </Descriptions.Item>
    );
  };

  return (
    <AppCard style={{maxWidth: 990}}>
      <Descriptions
        title='Compensation'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        {renderBilling()}
        {!requisitionModal.isDirectHire && !requisitionModal.isTemporary && (
          <Descriptions.Item label='Expenses Eligibility'>
            {requisitionModal.expensesEligible}
          </Descriptions.Item>
        )}
        {requisitionModal.isDirectHire ? (
          <Descriptions.Item label='Salary Period'>
            {BASE_SALARY_PERIOD[requisitionModal.base_salary_period]}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item
            label={`${isTemporary ? 'Base' : 'Billing'} Rate Period`}>
            {requisitionModal.billingRatePeriod}
          </Descriptions.Item>
        )}

        <Descriptions.Item label='FLSA Eligible' span={1}>
          {requisitionModal.flsaEligible}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title='Candidate Criteria'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Location(s)'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal.candidateCountries?.map((location, index) => (
              <Col key={index}>
                <AppTag
                  className={'border-none'}
                  shape={'square'}
                  label={location}
                />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Visa Status'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal?.candidateVisaStatus &&
              requisitionModal.candidateVisaStatus?.map((visa) => (
                <Col key={visa}>
                  <AppTag
                    className={'border-none'}
                    shape={'square'}
                    label={visa}
                  />
                </Col>
              ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item
          label='Minimum Experience'
          labelStyle={{width: '50%'}}>
          {requisitionModal.candidateMinimumExperience}
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Education'>
          {requisitionModal.candidateMinEducation}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Based In' span={1}>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal.supplierCountries?.map((location, index) => (
              <Col key={index}>
                <AppTag
                  className={'border-none'}
                  shape={'square'}
                  label={location}
                />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
      </Descriptions>
      {!!requisitionModal?.candidateRequiredChecks?.length && (
        <Typography.Title level={4} className='mb-4 mt-3'>
          Required Background Checks
        </Typography.Title>
      )}
      {requisitionModal.candidateRequiredChecks.map((item) => (
        <Typography.Paragraph key={item}>{item}</Typography.Paragraph>
      ))}
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
