import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Modal, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {RiCloseLine} from 'react-icons/ri';

import {WITHDRAW_OPTIONS_FROM_SUB_SUPPLIER} from '../../../../../../constants/candidate-statuses';

const WithdrawCandidateActions = ({
  isOpenModal,
  handleClose,
  handleSuccess,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
    }
  }, [form, isOpenModal]);

  return (
    <Modal
      className='submission-reject-modal'
      title='Withdraw Candidate'
      open={isOpenModal}
      cancelButtonProps={{type: 'primary', htmlType: 'button', ghost: true}}
      closeIcon={<RiCloseLine fontSize={24} />}
      okText='Withdraw Candidate'
      width={844}
      onOk={form.submit}
      onCancel={handleClose}
      destroyOnClose={true}>
      <Typography.Paragraph>
        If you would like to withdraw this candidate, please provide a reason
        for withdrawal and then click Withdraw Candidate button. If you&apos;d
        like to recogniser this action, click Cancel.
      </Typography.Paragraph>
      <Form
        onFinish={handleSuccess}
        layout='vertical'
        form={form}
        className='modal-reason-dropdown mt-5'>
        <Form.Item
          rules={[
            {
              required: true,
              message: getRequiredMessage('Candidate Status'),
            },
          ]}
          name='reject_reason'
          label='Reason for Withdrawal'
          style={{maxWidth: 260, width: '100%'}}>
          <AppSelect
            placeholder={getSelectPlaceHolder('status')}
            size={'middle'}
            style={{maxWidth: 260, width: '100%'}}
            options={WITHDRAW_OPTIONS_FROM_SUB_SUPPLIER}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

WithdrawCandidateActions.propTypes = {
  reasonFor: PropTypes.string,
  actionType: PropTypes.string,
  actionLabel: PropTypes.string,
  isOpenModal: PropTypes.bool,
  isContract: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  status: PropTypes.string,
  selectedCandidate: PropTypes.object,
};

export default WithdrawCandidateActions;
